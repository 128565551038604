import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
import sfondo from './contents/sfondo.png';
import {Row, Container, Col, Image} from 'react-bootstrap';
import cannabis from './contents/Cannabis.jpg'
import mcs from './contents/mcs.webp';
import './contents/popstyle.css';
import thc from './contents/thc.png'
import cbd from './contents/cbd.png'
import marocco from './contents/marocco.webp'
import gold from './contents/ignotes.webp'

function Notegold () {
    return (
     <div style={{backgroundImage: `url(${sfondo})` }}>
        <Navbarngl />
        
        <h1 style={{fontstyle: 'bold', fontFamily: 'Impact', fontSize: '60px', color: 'white'}}>NGLPop</h1>
        <div  className='div-centrale' style={{backgroundColor: 'white'}}>
        <p style={{marginLeft: '8px', fontSize: '12px'}}>12 Agosto 2024 * 10:15 Social Network </p>
        <Container>
       <h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> INSTAGRAM: PERCHE' LE NOTE DORATE</h1>
       <h2 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}>Il motivo è molto semplice: Le Olimpiadi 2024 di Parigi</h2>
       <p style={{marginLeft: '10px'}}>Dato che in questi giorni si è tenuta la fase finale delle Olimpiadi di Parigi 2024, Meta ha voluto celebrare le medaglie d'oro implementando questo easter egg in uno dei social network più famosi di questo momento. Questa nuova funzione trasformerà qualsiasi parola legata alle Olimpiadi, in una nota coplor oro.</p>


      <Row>
        <Col xs={6} md={4}>
          <Image src={gold} rounded />
          
        </Col>
</Row>

<p style={{marginLeft: '10px'}}>Fonte: BGR.com</p>
<h2 style={{  marginLeft: '15px'}}> Come usare questa funzione </h2>
<p style={{marginLeft: '10px'}}>1) Apri su Instagram la sezione messaggi <br /> 2) Seleziona le notes e scrivi una delle seguenti parole: Gold
Olympics <br />
Olympic  <br />
Olympiad <br />
Olympian <br />
Medal    <br />
Podium   <br />
GOAT     <br />
Champion <br />
Champs   <br />
Victory  <br />
🥇🏅🐐  <br />
Closing ceremony <br />
Torch            <br />
World Record     <br />
Summer Games     <br />
2024 games       <br />
2028 games <br/>
3) Pubblica la nota o con i prorpi amici, o con i propri amici più stretti.</p>
</Container>
</div>
<h3 style={{borderLeft: '20px', color: 'white'}}>Fonte:
    eu.usatoday.com (Inglese)
</h3>
       <Tops />
     </div>
    );
}

export default Notegold;

