// dashboardUtils.js
import Parse from 'parse';

export const fetchStatisticsData = async (setStatisticsData) => {
    const Statistics = Parse.Object.extend('Statistics');
    const query = new Parse.Query(Statistics);
    try {
        const results = await query.find();
        const data = results.map(result => ({
            month: result.get('month'),
            visits: result.get('visits'),
            device: result.get('device'),
            count: result.get('count'),
        }));
        setStatisticsData(data);
    } catch (error) {
        console.error('Errore durante il recupero dei dati statistici: ', error);
    }
};

export const checkCurrentUser = (navigate) => {
    const currentUser = Parse.User.current();
    if (!currentUser) {
        navigate('/login');
    }
};
