import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
import sfondo from './contents/sfondo.png';
import {Row, Container, Col, Image} from 'react-bootstrap';
import cannabis from './contents/Cannabis.jpg'
import mcs from './contents/mcs.webp';
import './contents/popstyle.css';
import thc from './contents/thc.png'
import cbd from './contents/cbd.png'
import marocco from './contents/marocco.webp'
import vignetta from './contents/vignetta.jpg'
import batteri from './contents/batteri.png'

function Scoregge () {
    const ChemicalFormula = ({ formula }) => {
        return (
          <span>
            {formula.split(/(\d+)/).map((part, index) =>
              /\d+/.test(part) ? <sub key={index}>{part}</sub> : part
            )}
          </span>
        );
      };
    return (
     <div style={{backgroundImage: `url(${sfondo})` }}>
        <Navbarngl />
        
        <h1 style={{fontstyle: 'bold', fontFamily: 'Impact', fontSize: '60px', color: 'white'}}>NGLPop</h1>
        <div  className='div-centrale' style={{backgroundColor: 'white'}}>
        <p style={{marginLeft: '8px', fontSize: '12px'}}>18 Luglio 2024 * 23:10 Chimica e Biologia </p>
        <Container>
       <h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> SCOREGGE, COME MAI LE BUTTIAMO E PERCHE' PUZZANO</h1>
       <h2 style={{  marginLeft: '15px'}}> Cos'è una scoreggia (flatulenza)</h2>
       <p style={{marginLeft: '10px'}}>Le scoregge, conosciute anche come <strong>flatulenze</strong>, sono accumuli di gas che si formano nel tratto gastrointestinale. Questi gas possono provenire dall’aria che ingeriamo quando mangiamo o beviamo, oppure possono essere prodotti direttamente nel nostro intestino. Quando questi gas vengono rilasciati attraverso l’ano, spesso producono un suono caratteristico e un odore sgradevole.Le flatulenze sono composte principalmente da <strong>Azoto (<span>{'N'.split(/(\d+)/).map((part, index) => /\d+/.test(part) ? <sub key={index}>{part}</sub> : part)}</span>)</strong>, <strong>Ossigeno (<span>{'O2'.split(/(\d+)/).map((part, index) => /\d+/.test(part) ? <sub key={index}>{part}</sub> : part)}</span>)</strong>, <strong>Metano (<span>{'CH4'.split(/(\d+)/).map((part, index) => /\d+/.test(part) ? <sub key={index}>{part}</sub> : part)}</span>)</strong> e <strong>Anidride Carbonica (<span>{'CO2'.split(/(\d+)/).map((part, index) => /\d+/.test(part) ? <sub key={index}>{part}</sub> : part)}</span>)</strong>. Questi sono gas inodori, quindi non causano cattivo odore. Tuttavia, ci sono anche altri gas che vengono rilasciati, come <strong>Acido Solfidrico (<span>{'H2S'.split(/(\d+)/).map((part, index) => /\d+/.test(part) ? <sub key={index}>{part}</sub> : part)}</span>)</strong> e <strong>Solfuro di Carbonile (<span>{'CS2'.split(/(\d+)/).map((part, index) => /\d+/.test(part) ? <sub key={index}>{part}</sub> : part)}</span>)</strong>, che contengono zolfo e sono responsabili del cattivo odore. Un altro composto che contribuisce all’odore è lo scatolo.</p>
       <Row>
        <Col xs={6} md={4}>
          <Image src={vignetta} rounded />
          <p>Vignetta inglese sulle flatulenze del 1798.</p>
          
        </Col>
</Row>

 
<h2 style={{  marginLeft: '15px'}}> Come si Formano </h2>
<p style={{marginLeft: '10px'}}><strong>Alcuni gas possono essere ingeriti durante i pasti o semplicemente respirando con la bocca.</strong> Tuttavia, altri gas, specialmente quelli puzzolenti, si formano attraverso la fermentazione batterica degli alimenti nell’intestino crasso.</p>

<p><strong>La fermentazione è un processo naturale in cui i batteri trasformano e scompongono le sostanze chimiche contenute nei nostri alimenti, come i carboidrati e le proteine.</strong> Durante questo processo, il glucosio viene degradato in composti organici più semplici, liberando energia sotto forma di ATP. Questo processo avviene principalmente nell’intestino crasso, dove vivono circa 400 specie di batteri, sia anaerobici (che non necessitano di ossigeno) sia aerobici (che necessitano di ossigeno).</p>

<p><strong>La fermentazione è un processo anaerobico, il che significa che avviene in assenza di ossigeno.</strong> I batteri nell’intestino crasso scompongono i carboidrati e le proteine in molecole più piccole, alcune delle quali possono diventare gassose. La presenza di una flora batterica equilibrata è fondamentale per la salute del nostro intestino e per prevenire problemi come la disbiosi intestinale.</p>

<p><strong>In sintesi, la flatulenza è il risultato della fermentazione batterica degli alimenti nell’intestino crasso, un processo naturale che scompone i nutrienti in molecole più piccole, alcune delle quali diventano gassose e possono causare cattivo odore.</strong></p>

<Row>
        <Col xs={12} sm={6} md={4} lg={3}>
          <Image src={batteri} rounded />
          <p>Flora batterica nell'intestino crasso.</p>
          
        </Col>
</Row>



      <h2 style={{marginLeft: '15px'}}>Perchè a volte puzzano e a volte no?</h2>
      <p style={{marginLeft: '10px'}}><p>Come detto in precedenza, <strong>i prodotti gassosi della fermentazione sono perlopiù inodori</strong>, come il metano e l’anidride carbonica. Tuttavia, possono anche produrre <strong>sostanze contenenti zolfo</strong>, come l’acido solfidrico e il metantiolo, che hanno un odore di uova marce. Altri composti prodotti includono lo scatolo e gli acidi grassi a catena corta.</p>

<p><strong>Gli acidi grassi a catena corta</strong>, come l’acido butirrico e l’acido propionico, possono essere riassorbiti dall’organismo e utilizzati come fonte di energia. Questi acidi non sono responsabili dell’odore delle flatulenze, ma possono contribuire all’odore di altre parti del corpo, come i piedi.</p>

<p>Secondo alcuni studi, <strong>l’assunzione di fagioli può causare un aumento significativo della produzione di gas</strong>. Questo è dovuto agli oligosaccaridi presenti nei fagioli, che sono resistenti alla digestione. Quando questi oligosaccaridi raggiungono l’intestino crasso, vengono fermentati dai batteri, producendo gas come sottoprodotto.</p>

<p>Infine, sembra che <strong>le flatulenze voluminose non abbiano un cattivo odore come quelle silenziose</strong>. Questo è dovuto all’assenza di molecole puzzolenti nelle flatulenze voluminose.</p>

  
</p>


        </Container>
</div>
<h3 style={{borderLeft: '20px'}}>Fonti: Geopop, Chimica Online, My Personaltrainer, Ciboedisturbiaddominali.it e NGL Cila
    
</h3>
       <Tops />
     </div>
    );
}

export default Scoregge;