import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { Container, Row, Col, Button } from "react-bootstrap";


function Progettistudios() {
    const [showAll, setShowAll] = useState(false);
    const [isMobile] = useState(window.innerWidth <= 768);

    const newsItems = [
        {
            img: 'https://pdfa.org/wp-content/uploads/2024/04/gltf-doc-300x292.png',
            title: "Visualizzatore modelli 3D GTLF",
            text: "Sapevi che nel sito web è possibile visualizzare i modelli 3D? Clicca il pulsante qua sotto per accedere al visualizzatore (NOTA: Funziona solo con modelli con estensione .glb e texture integrata).",
            link: "/visualizzatore"
        },
        {
            img: 'https://upload.wikimedia.org/wikipedia/commons/d/d8/Minecraft_cube.svg',
            title: "Visualizzatore modelli 3D Minecraft",
            text: "Vuoi visualizzare modelli 3D di Minecraft? Usa il nostro tool!",
            link: "https://cilarp.nglcila.com/modelview.html"
        },
        {
            img: 'https://cilarp.nglcila.com/contents/img/ngllogo.jpg',
            title: "Scopri CilaRP (In produzione)",
            text: "Scopri CilaRP, un nuovo progetto di Minecraft Server Roleplay ambientato nella Lomellina, per un'esperienza di gioco meno monotona e più divertente.",
            link: "https://cilarp.nglcila.com"
        }
    ];

    const fadeIn = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 1000 }
    });

    return (
        <Container className="p-4" style={{ color: '#333', backgroundColor: '#f0f0f0', maxWidth: '100%', fontFamily: 'Inter, sans-serif' }}>
            <animated.h1 style={{ textAlign: 'center', fontWeight: 'bold', color: '#333', ...fadeIn }}>
                I nostri progetti
            </animated.h1>
            <Row className="mt-4">
                {newsItems.slice(0, isMobile && !showAll ? 1 : newsItems.length).map((item, index) => (
                    <Col key={index} md={4} className="mb-4">
                        <animated.div style={{ ...fadeIn, backgroundColor: '#ffffff', color: '#333', borderRadius: '0', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', border: '2px solid #333', height: '100%' }}>
                            <img
                                src={item.img}
                                alt={item.title}
                                style={{ borderRadius: '0', width: '100%', height: '300px', objectFit: 'cover', marginBottom: '10px' }}
                                loading="lazy"
                            />
                            <h3>{item.title}</h3>
                            <p>{item.text}</p>
                            <Button variant="dark" href={item.link} target="_blank" rel="noopener noreferrer">
                                Vai al link
                            </Button>
                        </animated.div>
                    </Col>
                ))}
            </Row>
            {isMobile && (
                <div style={{ textAlign: 'center' }}>
                    <Button variant="primary" onClick={() => setShowAll(!showAll)} style={{ display: 'block', margin: '0 auto' }}>
                        {showAll ? "Mostra meno" : "Mostra altro"}
                    </Button>
                </div>
            )}
            <style jsx>{`
                @media (max-width: 768px) {
                    h1 {
                        font-size: 1.5rem;
                    }
                    h3 {
                        font-size: 1.2rem;
                    }
                    p {
                        font-size: 0.9rem;
                    }
                }
                .row {
                    display: flex;
                    flex-wrap: wrap;
                }
                .col {
                    display: flex;
                    flex-direction: column;
                }
                .col > div {
                    flex: 1;
                }
            `}</style>
        </Container>
    );
}

export default Progettistudios;




