import React from "react";
import { Navbar, Container } from "react-bootstrap";


import Navbarngl from "./navbarngl";
import Tops from "./top";
import Game from "./game";


function Minigioco () {
    return(
     <div className="minigioco" style={{backgroundColor: 'white'}}>
        <Navbarngl />
        <h1 style={{textAlign: 'center'}}>Minigiochi di NGL Cila</h1>
      <Game />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
  

      
      <Tops />
      
     </div>
    );
}

export default Minigioco;