import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaAndroid, FaApple, FaGooglePlay } from 'react-icons/fa';
import Navbarngl from './navbarngl';
import Tops from './top';

const DownloadPage = () => {
  return (
    <div>
      <Navbarngl />
      <Container className="text-center" style={{fontFamily: 'Inter, sans-serif'}}>
        <Row className="my-4">
          <Col>
            <h1>Link per i vari download</h1>
            <h2 style={{ fontSize: '40px' }}>Scarica PWA e segui le istruzioni nel link</h2>
            <div className="d-flex justify-content-center my-3">
              <a href="https://idea-studios.web.app/" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-primary" className="mx-2">
                  <FaAndroid size={30} /> Android
                </Button>
              </a>
              <a href="https://idea-studios.web.app/" target="_blank" rel="noopener noreferrer">
                <Button variant="outline-primary" className="mx-2">
                  <FaApple size={30} /> iOS
                </Button>
              </a>
            </div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            <h2>Scarica l'app nativa (Alpha) solo android</h2>
            <a href="https://expo.dev/artifacts/eas/iKqyRDuU4syZRqGMLBN5Jb.apk" target="_blank" rel="noopener noreferrer">
              <Button variant="success" className="d-flex align-items-center mx-auto">
                <FaGooglePlay size={30} className="me-2" /> Scarica
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
      <Tops />
    </div>
  );
};

export default DownloadPage;

