import React, { useState, useEffect } from 'react';
import Parse from 'parse';

function StatoCollegamento() {
  const [messaggio, setMessaggio] = useState('');

  useEffect(() => {
    // Chiama la funzione Cloud Code "statoCollegamento"
    Parse.Cloud.run('statoCollegamento').then((risposta) => {
      // Imposta lo stato con il messaggio ricevuto dal server
      setMessaggio(risposta);
    }).catch((errore) => {
      // Gestisci l'errore qui
      console.error('Errore durante il collegamento con Back4App:', errore);
    });
  }, []);

  return (
    <div style={{backgroundColor: 'white'}}>
      {messaggio ? <p>{messaggio}</p> : <p>Caricamento...</p>}
    </div>
  );
}

export default StatoCollegamento;




