import React, { useState } from 'react';
import { Offcanvas, Button, Row, Col } from 'react-bootstrap';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import WeatherWidget from './WeatherWidget'; // Assicurati che il percorso sia corretto
import ReviewWidget from './ReviewWidget'; // Assicurati che il percorso sia corretto
import InstagramFollowersCountdown from './Followecounter';
import Followecounter from './Followecounter';

const Sidebar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FaArrowRight onClick={handleShow} className="menu-icon" />

      <Offcanvas show={show} onHide={handleClose} placement="start" className="sidebar">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontFamily: 'Inter, sans-serif'}}>NGL Cila</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FaArrowLeft onClick={handleClose} className="back-button" />
          <WeatherWidget />
          <Row style={{ width: '100%' }}>
            <Col>
              <ReviewWidget />
            </Col>
          </Row>
         
        </Offcanvas.Body>
      </Offcanvas>

      <style jsx>{`
        .menu-icon {
          font-size: 2rem;
          cursor: pointer;
          position: fixed;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          z-index: 1000;
        }
        .sidebar {
          background-color: #001f3f;
          color: white;
          width: 250px;
          height: calc(100vh - 60px); /* Adjust height to be below the navbar */
          position: fixed;
          top: 60px; /* Adjust top to be below the navbar */
          left: 0;
          padding: 20px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .back-button {
          font-size: 1.5rem;
          cursor: pointer;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
        }
        .title {
          font-size: 20px;
        }
        .subtitle {
          font-size: 10px;
        }
        @media (max-width: 768px) {
          .title {
            font-size: 40px;
          }
          .subtitle {
            font-size: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default Sidebar;









