// Game.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { FaRegSmile, FaGhost, FaArrowCircleUp, FaArrowCircleDown, FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { generateFood, generateGhosts, handleKeyDown, handleArrowClick, resetGame } from './js/gameutils';

const Game = () => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [score, setScore] = useState(0);
  const [showGameOver, setShowGameOver] = useState(false);
  const [showVictory, setShowVictory] = useState(false);
  const [food, setFood] = useState(generateFood());
  const [ghosts, setGhosts] = useState(generateGhosts());
  const [ghostSpeed, setGhostSpeed] = useState(1000);

  useEffect(() => {
    const handleKeyDownWrapper = (e) => handleKeyDown(e, setPosition);
    window.addEventListener('keydown', handleKeyDownWrapper);
    return () => {
      window.removeEventListener('keydown', handleKeyDownWrapper);
    };
  }, []);

  useEffect(() => {
    // Simula la raccolta di punti
    food.forEach((f, index) => {
      if (Math.abs(position.top - f.top) < 20 && Math.abs(position.left - f.left) < 20) {
        setFood((prev) => prev.filter((_, i) => i !== index));
        setScore((prev) => prev + 10);
      }
    });

    // Controlla collisioni con i fantasmi
    ghosts.forEach((ghost) => {
      if (Math.abs(position.top - ghost.top) < 20 && Math.abs(position.left - ghost.left) < 20) {
        setShowGameOver(true);
      }
    });

    // Condizioni di vittoria
    if (score >= 100) {
      setShowVictory(true);
    }

    // Ricompare il cibo e aumenta la velocità dei fantasmi
    if (food.length === 0) {
      setFood(generateFood());
      setGhostSpeed((prev) => Math.max(prev - 100, 200));
    }
  }, [position, score, food, ghosts]);

  useEffect(() => {
    const interval = setInterval(() => {
      setGhosts((prevGhosts) =>
        prevGhosts.map((ghost) => ({
          ...ghost,
          top: Math.min(Math.max(ghost.top + (Math.random() > 0.5 ? 10 : -10), 0), 290),
          left: Math.min(Math.max(ghost.left + (Math.random() > 0.5 ? 10 : -10), 0), 290),
        }))
      );
    }, ghostSpeed);
    return () => clearInterval(interval);
  }, [ghostSpeed]);

  return (
    <>
      <Container className="game-container" style={styles.gameContainer}>
        <Row>
          <Col>
            <div className="maze" style={styles.maze}>
              <div className="pacman" style={{ ...styles.pacman, top: `${position.top}px`, left: `${position.left}px`, transition: 'top 0.2s, left 0.2s' }}>
                <FaRegSmile size={30} color="yellow" />
              </div>
              {ghosts.map((ghost, index) => (
                <div key={index} className="ghost" style={{ ...styles.ghost, top: `${ghost.top}px`, left: `${ghost.left}px`, transition: 'top 0.2s, left 0.2s' }}>
                  <FaGhost size={30} color="red" />
                </div>
              ))}
              {food.map((f, index) => (
                <div key={index} className="food" style={{ ...styles.food, top: `${f.top}px`, left: `${f.left}px` }}></div>
              ))}
            </div>
            <div className="score" style={styles.score}>Punteggio: {score}</div>
          </Col>
        </Row>
        <Row className="controls" style={styles.controls}>
          <Col>
            <FaArrowCircleUp size={40} color="white" onClick={() => handleArrowClick('up', setPosition)} />
          </Col>
        </Row>
        <Row className="controls" style={styles.controls}>
          <Col>
            <FaArrowCircleLeft size={40} color="white" onClick={() => handleArrowClick('left', setPosition)} />
          </Col>
          <Col>
            <FaArrowCircleDown size={40} color="white" onClick={() => handleArrowClick('down', setPosition)} />
          </Col>
          <Col>
            <FaArrowCircleRight size={40} color="white" onClick={() => handleArrowClick('right', setPosition)} />
          </Col>
        </Row>

        <Modal show={showGameOver} onHide={() => setShowGameOver(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Game Over</Modal.Title>
          </Modal.Header>
          <Modal.Body>Hai perso! Vuoi riprovare?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowGameOver(false)} as='a' href='/home'>Chiudi</Button>
            <Button variant="primary" onClick={() => resetGame(setPosition, setScore, setFood, setGhosts, setGhostSpeed, setShowGameOver, setShowVictory)}>Ricomincia</Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showVictory} onHide={() => setShowVictory(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Vittoria!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Hai vinto! Complimenti!</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => resetGame(setPosition, setScore, setFood, setGhosts, setGhostSpeed, setShowGameOver, setShowVictory)}>Ricomincia</Button>
            <Button variant="secondary" onClick={() => setShowGameOver(false)} as='a' href='/home'>Chiudi</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

const styles = {
  gameContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    paddingTop: '56px', // Altezza della navbar
    overflow: 'hidden', // Disabilita lo scrolling
  },
  maze: {
    position: 'relative',
    width: '300px',
    height: '300px',
    backgroundColor: 'black',
    border: '2px solid yellow',
  },
  pacman: {
    position: 'absolute',
  },
  ghost: {
    position: 'absolute',
  },
  food: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    backgroundColor: 'white',
    borderRadius: '50%',
  },
  score: {
    color: '#fff',
    marginTop: '10px',
    textAlign: 'center',
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
};

export default Game;





























