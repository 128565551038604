import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { FaNewspaper } from "react-icons/fa";
import Parse from 'parse';

const NewsList = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [newsItems, setNewsItems] = useState([]);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsMobile(true);
        }

        // Inizializza Parse
       

        // Funzione per ottenere le ultime tre notizie dal database
        const fetchNews = async () => {
            const News = Parse.Object.extend("News");
            const query = new Parse.Query(News);
            query.descending("createdAt"); // Ordina per data di creazione in ordine decrescente
            query.limit(3); // Limita a 3 notizie
            try {
                const results = await query.find();
                const newsData = results.map(result => ({
                    title: result.get('title'),
                    content: result.get('content')
                }));
                setNewsItems(newsData);
            } catch (error) {
                console.error("Errore nel recupero delle notizie:", error);
            }
        };

        fetchNews();
    }, []);

    return (
        <Container className="p-4 news-list-container wide-container">
            <div className="header">
                <FaNewspaper className="icon" style={{ fontSize: '50px' }} />
                <h1 className="title" style={{fontSize: '50px', fontFamily: 'Inter, sans-serif'}}>ULTIME NEWS!</h1>
            </div>
            <Row className="mt-4 flex-row flex-wrap">
                {newsItems.map((item, index) => (
                    <Col md={4} className="mb-4 d-flex" key={index}>
                        <Card className="h-100 animate-card flex-grow-1" style={{ backgroundColor: '#003366', borderRadius: '15px', border: '2px solid white' }}>
                            <Card.Body className="d-flex flex-column justify-content-center text-white">
                                <Card.Title style={{fontFamily: 'Inter, sans-serif', fontWeight: 'bold'}}>{item.title}</Card.Title>
                                <Card.Text style={{fontFamily: 'Inter, sans-serif'}}>{item.content}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <style jsx>{`
                .wide-container {
                    max-width: 1200px;
                }
                .animate-card {
                    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
                }
                .animate-card:hover {
                    transform: translateY(-10px);
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                }
                .header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 20px;
                }
                .icon {
                    margin-right: 10px;
                    font-size: 2rem;
                }
                .title {
                    font-family: 'Inter, sans-serif';
                    text-align: center;
                    font-size: 50px;
                }
                @media (min-width: 769px) {
                    .card-body {
                        display: flex;
                        align-items: center;
                    }
                    .card-title, .card-text {
                        margin-left: 20px;
                    }
                    .card-title {
                        font-size: 1.5rem; /* Aumenta la dimensione del titolo */
                    }
                    .card-text {
                        font-size: 1.2rem; /* Aumenta la dimensione del testo */
                    }
                }
            `}</style>
        </Container>
    );
}

export default NewsList;






















 


























