import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { FaUser, FaInstagram, FaTshirt, FaCircle, FaCreditCard } from "react-icons/fa";
import { fetchOrdini } from './js/ordiniutils';
import Parse from 'parse';

const PARSE_APPLICATION_ID = 'h6n3hXeAyPq4Q9VYaiI1n5FnRIxoeCdlrynUbAMA';
const PARSE_JAVASCRIPT_KEY = 'Ts74nlTiApt0TLezItum716SuEnWEIsujWFpjsct';
const PARSE_HOST_URL = 'https://parseapi.back4app.com';

Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

function OrdiniEffettuati() {
    const [ordini, setOrdini] = useState([]);

    useEffect(() => {
        fetchOrdini(setOrdini);
    }, []);

    return (
        <Container>
            <h1>Ordini effettuati</h1>
            <Row>
                {ordini.map((ordine, index) => (
                    <Col key={index} md={4}>
                        <Card className="mb-4">
                            <Card.Body>
                                <Card.Title>Ordine #{index + 1}</Card.Title>
                                <Card.Text>
                                    <div className="d-flex align-items-center mb-2">
                                        <FaUser className="me-2" /> Nome: {ordine.nome}
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <FaInstagram className="me-2" /> Nome Instagram: {ordine.nomeig}
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <FaTshirt className="me-2" /> Taglia: {ordine.taglia}
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <FaCircle className="me-2" color={ordine.colore} /> Colore: {ordine.colore}
                                    </div>
                                    <div className="d-flex align-items-center mb-2">
                                        <FaCreditCard className="me-2" /> Metodo di Pagamento: {ordine.pagamento}
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default OrdiniEffettuati;


