// CardForm.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import { FaImage, FaHeading, FaAlignLeft, FaLink } from 'react-icons/fa';
import { handleSubmit } from './js/cardutils';

function CardForm() {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);
  const [buttonLink, setButtonLink] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Form onSubmit={(e) => handleSubmit(e, title, text, image, buttonLink, setShowModal)}>
            <Form.Group controlId="formTitle">
              <Form.Label><FaHeading /> Titolo</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formText">
              <Form.Label><FaAlignLeft /> Descrizione</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={text}
                onChange={(e) => setText(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formImage">
              <Form.Label><FaImage /> Immagine</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
                required
              />
            </Form.Group>
            <Form.Group controlId="formButtonLink">
              <Form.Label><FaLink /> Link del Pulsante</Form.Label>
              <Form.Control
                type="url"
                value={buttonLink}
                onChange={(e) => setButtonLink(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Salva
            </Button>
          </Form>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Conferma</Modal.Title>
        </Modal.Header>
        <Modal.Body>L'NGL pop di oggi è stato pubblicato, torna tra qualche giorno!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default CardForm;




