import React, { useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { FaPhone, FaEnvelope, FaFilePdf, FaInstagram, FaWhatsapp, FaTiktok } from 'react-icons/fa';
import Licenza from './contents/Licenza.pdf';
import NglLogo from './contents/ngllogo.jpg';

function Tops() {
    const [showMore, setShowMore] = useState(false);

    return (
        <Container fluid style={{ backgroundColor: 'black', color: 'white', padding: '2rem', fontFamily: 'Inter, sans-serif' }}>
            <Row className="justify-content-center mb-4">
                <Col xs="auto">
                    <h1 className="text-center">Copyright © 2024/Ngl Cila, tutti i diritti riservati</h1>
                </Col>
            </Row>
            <Row className="justify-content-between px-4">
                <Col md={6} className="mb-4">
                    <h2>Cos'è NGLCila?</h2>
                    <p style={{ display: showMore ? 'block' : '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: showMore ? 'none' : 3, overflow: 'hidden' }}>
                        NGLCila è un progetto di giornalismo e gossip dedicato ai ragazzi di Cilavegna e dintorni. La nostra missione è fornire notizie fresche, divertenti e coinvolgenti che riguardano la comunità locale. Che si tratti di eventi, storie di persone, o semplici curiosità, NGLCila è qui per tenervi informati e intrattenuti.
                    </p>
                    <Button variant="link" onClick={() => setShowMore(!showMore)} className="text-teal">
                        {showMore ? "Mostra meno" : "Mostra tutto"}
                    </Button>
                </Col>
                <Col md={4}>
                    <ul className="list-unstyled">
                        <li><a href="/chisiamo" className="text-white">Chi siamo</a></li>
                        <li><a href="/gdpr" className="text-white">Termini e uso dei dati personali (GDPR)</a></li>
                        <li><a href="/contatti" className="text-white">Contatti</a></li>
                        <li>
                            <a href={Licenza} className="text-white" target="_blank" rel="noopener noreferrer">
                                <FaFilePdf className="mr-2" />
                                Scarica la Licenza
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="justify-content-center mt-4">
                <Image src={NglLogo} alt="NGLCila Logo" style={{ width: '150px' }} />
            </Row>
            <Row className="justify-content-center mt-4">
                <Col xs="auto">
                    <a href="https://instagram.com/ngl_cila" className="text-white mr-4">
                        <FaPhone className="mr-2" />
                        Scrivici su Instagram in DM!
                    </a>
                </Col>
                <Col xs="auto">
                    <a href="mailto:info@nglcila.com" className="text-white">
                        <FaEnvelope className="mr-2" />
                        info@nglcila.com
                    </a>
                </Col>
            </Row>
            <Row className="justify-content-end mt-4">
                <Col xs="auto">
                    <Button href="https://instagram.com/ngl_cila" style={{ background: 'linear-gradient(to right, #833AB4, #FD1D1D, #F56040)', borderRadius: '50%', padding: '1rem' }}>
                        <FaInstagram style={{ color: 'white' }} />
                    </Button>
                </Col>
                
                <Col xs="auto">
                    <Button href="https://whatsapp.com/channel/0029VajF80s6BIEcButOf50E" style={{ background: '#25D366', borderRadius: '50%', padding: '1rem' }}>
                        <FaWhatsapp style={{ color: 'white' }} />
                    </Button>
                </Col>
                <Col xs="auto">
                    <Button href="https://chat.whatsapp.com/BvxvOf2O8cWAJsjlt1Avcm" style={{ background: '#25D366', borderRadius: '50%', padding: '1rem' }}>
                        <FaWhatsapp style={{ color: 'white' }} />
                    </Button>
                </Col>
                <Col xs="auto">
    <Button href="https://www.tiktok.com/@ngl_cila?_t=8pip3VNfjG4&_r=1" style={{ background: '#000000', borderRadius: '50%', padding: '1rem' }}>
        <FaTiktok style={{ color: '#ffffff' }} />
    </Button>
</Col>
            </Row>
        </Container>
    );
}

export default Tops;








