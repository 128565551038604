import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

const NewsForm = ({ saveNews }) => {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [img, setImg] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        saveNews({ title, text, img });
        setTitle("");
        setText("");
        setImg("");
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle">
                <Form.Label>Titolo</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Inserisci il titolo"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formText">
                <Form.Label>Descrizione</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Inserisci la descrizione"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
            </Form.Group>

            <Form.Group controlId="formImg">
                <Form.Label>Immagine (URL)</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Inserisci l'URL dell'immagine"
                    value={img}
                    onChange={(e) => setImg(e.target.value)}
                />
            </Form.Group>

            <Button variant="primary" type="submit">
                Aggiungi Notizia
            </Button>
        </Form>
    );
};

export default NewsForm;


