import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";

export default function Eventi() {
  return (
    <>
    <Navbarngl />
      <div
        className="container"
        style={{
          borderWidth: "0px",
          borderStyle: "solid",
          borderColor: "rgb(229, 231, 235)",
          border: "0px solid rgb(229, 231, 235)",
          boxSizing: "border-box",
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: "calc(1.5rem*.5)",
          paddingRight: "calc(1.5rem*.5)",
          width: "100%",
          maxWidth: "1320px",
        }}
      >
        <div
          style={{
            borderWidth: "0px",
            borderStyle: "solid",
            borderColor: "rgb(229, 231, 235)",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            margin: "20px 0px",
            textAlign: "center",
          }}
        >
          <h1
            style={{
              borderWidth: "0px",
              borderStyle: "solid",
              borderColor: "rgb(229, 231, 235)",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              margin: "0px",
              color: "var(--bs-heading-color)",
              fontWeight: 500,
              lineHeight: 1.2,
              marginBottom: "0.5rem",
              marginTop: "0px",
              fontFamily: "Inter, sans-serif",
              fontSize: "2.5rem",
            }}
          >
            Eventi di NGL Cila
          </h1>
          <p
            style={{
              borderWidth: "0px",
              borderStyle: "solid",
              borderColor: "rgb(229, 231, 235)",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              margin: "0px",
              marginBottom: "1rem",
              marginTop: "0px",
              fontFamily: "Inter, sans-serif",
              fontSize: "1.2rem",
            }}
          >
            Qui trovi tutti gli eventi di NGL Cila, con eventuali premi
          </p>
        </div>
        <div
          className="row"
          style={{
            borderWidth: "0px",
            borderStyle: "solid",
            borderColor: "rgb(229, 231, 235)",
            border: "0px solid rgb(229, 231, 235)",
            boxSizing: "border-box",
            display: "flex",
            flexWrap: "wrap",
            marginLeft: "calc(1.5rem*-.5)",
            marginRight: "calc(1.5rem*-.5)",
            marginTop: "calc(0*-1)",
          }}
        >
          <div
            className="mb-4 col-md-6"
            style={{
              borderWidth: "0px",
              borderStyle: "solid",
              borderColor: "rgb(229, 231, 235)",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              marginTop: "0",
              maxWidth: "100%",
              paddingLeft: "calc(1.5rem*.5)",
              paddingRight: "calc(1.5rem*.5)",
              flex: "0 0 auto",
              flexShrink: 0,
              width: "50%",
              marginBottom: "1.5rem",
            }}
          >
            <div
              className="card"
              style={{
                borderWidth: "0px",
                borderStyle: "solid",
                borderColor: "rgb(229, 231, 235)",
                boxSizing: "border-box",
                border: "1px solid rgba(0,0,0,.175)",
                borderRadius: "0.375rem",
                overflowWrap: "break-word",
                backgroundClip: "initial",
                backgroundColor: "#fff",
                color: "#212529",
                display: "flex",
                flexDirection: "column",
                height: "var(--bs-card-height)",
                minWidth: "0px",
                position: "relative",
                animation: "1s ease 0s 1 normal none running fadeInUp",
              }}
            >
              <div
                className="card-body"
                style={{
                  borderWidth: "0px",
                  borderStyle: "solid",
                  borderColor: "rgb(229, 231, 235)",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  flex: "1 1 auto",
                  padding: "1rem 1rem",
                  color: "var(--bs-card-color)",
                }}
              >
                <div
                  className="card-title h5"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    fontWeight: 500,
                    lineHeight: 1.2,
                    marginTop: "0px",
                    fontSize: "1.25rem",
                    color: "var(--bs-card-title-color)",
                    marginBottom: "0.5rem",
                  }}
                >
                  Example Event
                </div>
                <p
                  className="card-text"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    margin: "0px",
                    marginBottom: "1rem",
                    marginTop: "0px",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <svg
                    height="1em"
                    width="1em"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 384 512"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "rgb(229, 231, 235)",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      style={{
                        borderWidth: "0px",
                        borderStyle: "solid",
                        borderColor: "rgb(229, 231, 235)",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                  </svg>{" "}
                  Cilavegna
                </p>
                <p
                  className="card-text"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    margin: "0px",
                    marginBottom: "1rem",
                    marginTop: "0px",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <svg
                    height="1em"
                    width="1em"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "rgb(229, 231, 235)",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                      style={{
                        borderWidth: "0px",
                        borderStyle: "solid",
                        borderColor: "rgb(229, 231, 235)",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                  </svg>{" "}
                  2024-09-10
                </p>
                <p
                  className="card-text"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    margin: "0px",
                    marginTop: "0px",
                    fontFamily: "Inter, sans-serif",
                    marginBottom: "0px",
                  }}
                >
                  <svg
                    height="1em"
                    width="1em"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "rgb(229, 231, 235)",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                      style={{
                        borderWidth: "0px",
                        borderStyle: "solid",
                        borderColor: "rgb(229, 231, 235)",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                  </svg>{" "}
                  Example description
                </p>
              </div>
            </div>
          </div>
          <div
            className="mb-4 col-md-6"
            style={{
              borderWidth: "0px",
              borderStyle: "solid",
              borderColor: "rgb(229, 231, 235)",
              border: "0px solid rgb(229, 231, 235)",
              boxSizing: "border-box",
              marginTop: "0",
              maxWidth: "100%",
              paddingLeft: "calc(1.5rem*.5)",
              paddingRight: "calc(1.5rem*.5)",
              flex: "0 0 auto",
              flexShrink: 0,
              width: "50%",
              marginBottom: "1.5rem",
            }}
          >
            <div
              className="card"
              style={{
                borderWidth: "0px",
                borderStyle: "solid",
                borderColor: "rgb(229, 231, 235)",
                boxSizing: "border-box",
                border: "1px solid rgba(0,0,0,.175)",
                borderRadius: "0.375rem",
                overflowWrap: "break-word",
                backgroundClip: "initial",
                backgroundColor: "#fff",
                color: "#212529",
                display: "flex",
                flexDirection: "column",
                height: "var(--bs-card-height)",
                minWidth: "0px",
                position: "relative",
                animation: "1s ease 0s 1 normal none running fadeInUp",
              }}
            >
              <div
                className="card-body"
                style={{
                  borderWidth: "0px",
                  borderStyle: "solid",
                  borderColor: "rgb(229, 231, 235)",
                  border: "0px solid rgb(229, 231, 235)",
                  boxSizing: "border-box",
                  flex: "1 1 auto",
                  padding: "1rem 1rem",
                  color: "var(--bs-card-color)",
                }}
              >
                <div
                  className="card-title h5"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    fontWeight: 500,
                    lineHeight: 1.2,
                    marginTop: "0px",
                    fontSize: "1.25rem",
                    color: "var(--bs-card-title-color)",
                    marginBottom: "0.5rem",
                  }}
                >
                  Example event
                </div>
                <p
                  className="card-text"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    margin: "0px",
                    marginBottom: "1rem",
                    marginTop: "0px",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <svg
                    height="1em"
                    width="1em"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 384 512"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "rgb(229, 231, 235)",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      style={{
                        borderWidth: "0px",
                        borderStyle: "solid",
                        borderColor: "rgb(229, 231, 235)",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                  </svg>{" "}
                  Cilavegna
                </p>
                <p
                  className="card-text"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    margin: "0px",
                    marginBottom: "1rem",
                    marginTop: "0px",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <svg
                    height="1em"
                    width="1em"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "rgb(229, 231, 235)",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"
                      style={{
                        borderWidth: "0px",
                        borderStyle: "solid",
                        borderColor: "rgb(229, 231, 235)",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                  </svg>{" "}
                  2024-10-05
                </p>
                <p
                  className="card-text"
                  style={{
                    borderWidth: "0px",
                    borderStyle: "solid",
                    borderColor: "rgb(229, 231, 235)",
                    border: "0px solid rgb(229, 231, 235)",
                    boxSizing: "border-box",
                    margin: "0px",
                    marginTop: "0px",
                    fontFamily: "Inter, sans-serif",
                    marginBottom: "0px",
                  }}
                >
                  <svg
                    height="1em"
                    width="1em"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      borderWidth: "0px",
                      borderStyle: "solid",
                      borderColor: "rgb(229, 231, 235)",
                      border: "0px solid rgb(229, 231, 235)",
                      boxSizing: "border-box",
                      display: "block",
                      verticalAlign: "middle",
                    }}
                  >
                    <path
                      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                      style={{
                        borderWidth: "0px",
                        borderStyle: "solid",
                        borderColor: "rgb(229, 231, 235)",
                        border: "0px solid rgb(229, 231, 235)",
                        boxSizing: "border-box",
                      }}
                    />
                  </svg>{" "}
                  Example description
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
html {
  border-width: 0px;
  border-style: solid;
  border-color: rgb(229, 231, 235);
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  font-size: 16px;
  overflow-x: hidden;
  scroll-padding-top: 64px;
  text-size-adjust: 100%;
  font-feature-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-variation-settings: normal;
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
  padding: 0px;
}

body {
  border-width: 0px;
  border-style: solid;
  border-color: rgb(229, 231, 235);
  border: 0px solid rgb(229, 231, 235);
  box-sizing: border-box;
  min-height: 100vh;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  margin: 0px;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  color: #212529;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: var(--bs-body-text-align);
}
`,
        }}
      />
      <Tops />
    </>
  );
}
