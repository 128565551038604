import React from "react";
import sfondo from './contents/sfondo.png';
import { Container, Row, Col, Image } from "react-bootstrap";
import Tops from "./top";
import Navbarngl from "./navbarngl";
import '@google/model-viewer'
import render from './contents/0380.avif'
import interni from './contents/download.jpg'
import koldy from './contents/koldy.glb'
function Creazionevideogiochi() {
    return(
        <div style={{backgroundImage: `url(${sfondo})` }}>
    <Navbarngl />
 <h1 style={{fontstyle: 'bold', fontFamily: 'Impact', fontSize: '60px', color: 'white'}}>NGLPop</h1>
 <div  className='div-centrale' style={{backgroundColor: 'white'}}>
        <p style={{marginLeft: '8px', fontSize: '12px'}}>23 Agosto 2024 * 13:27 Videogiochi e Tecnologia</p>
<Container>
<h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> Videogiochi: Come vengono creati?</h1>
<p style={{marginLeft: '10px'}}>
I <strong>videogiochi</strong> sono ormai il <strong>passatempo preferito</strong> di tutti noi ragazzi, dai bambini agli adolescenti, e persino alcuni adulti. Passiamo da <strong>titoli competitivi di fama mondiale</strong> come <strong>Fortnite</strong>, <strong>League of Legends</strong>, e <strong>GTA</strong>, a <strong>Minecraft</strong>, che è molto più di un semplice videogioco grazie alle infinite possibilità che offre. E non dimentichiamo i <strong>titoli mobile</strong> come <strong>Brawl Stars</strong>. Ma vi siete mai chiesti come vengono creati?
<Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src='https://upload.wikimedia.org/wikipedia/commons/c/c3/Rocket_League_logo.svg' rounded fluid />
    <p>Logo di Rocket League, un noto videogioco di calcio tra auto da corsa. Fonte: Wikipedia Immages</p>
  </Col>
</Row>
<h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> Come si creano</h1>
<p>Alla base di ogni videogioco, prima della loro produzione, vi vanno le <strong>idee</strong>. Ebbene sì, tutti i videogiochi moderni sono sempre partiti da <strong>idee</strong>, originali o copie, ma che unendosi formano il <strong>prototipo ideale</strong> di un videogioco. Può esserci stata anche una <strong>progettazione su carta e penna</strong> prima di ogni creazione per valutare le <strong>meccaniche</strong> e lo <strong>scopo</strong> del videogioco. Le idee devono anche corrispondere al <strong>tema</strong> del videogioco, poiché idee fuori tema rendono il videogioco senza senso.</p>
    
    <h2>La realizzazione del videogioco.</h2>
    <h3>Parte Grafica</h3>
    <p>
        Dopo aver scelto il tema, ideato i personaggi, le meccaniche e l'ambiente di gioco, si può partire con la realizzazione vera e propria. Il team di sviluppo qui si divide nelle loro "specializzazioni". I <strong>concept artist</strong> creano i <strong>modelli 3D</strong> e le <strong>texture</strong>, usando software come <strong>Blender</strong>, <strong>3ds Max</strong> e <strong>AutoCAD</strong> per i modelli 3D, mentre utilizzano principalmente <strong>Photoshop</strong> o programmi simili per le texture.
    </p>
    <p>
        Ed è proprio qui che, da una progettazione su carta e penna, usando anche colori per il colore dei personaggi, si realizzano gli <strong>NPC</strong> (ovvero i personaggi non giocanti), le eventuali <strong>skin</strong>, il <strong>mondo di gioco</strong>, ecc. Inoltre, si occupano anche della modifica dei <strong>suoni</strong> che ogni personaggio o comportamento ambientale deve avere, utilizzando programmi come <strong>Audacity</strong> o altri software professionali.
    </p>
    <p>
        La creazione grafica dei videogiochi è un processo che richiede molta creatività e precisione. Gli artisti devono assicurarsi che ogni elemento visivo sia perfettamente integrato e che il gioco offra un'esperienza visiva straordinaria. Ogni dettaglio, dai personaggi agli ambienti, deve essere curato nei minimi particolari per creare un mondo di gioco immersivo e coinvolgente.
    </p>
    <Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src='https://upload.wikimedia.org/wikipedia/commons/a/af/Adobe_Photoshop_CC_icon.svg' rounded fluid />
    <p>Logo di Photoshop (Spoiler, lo usiamo anche noi nel team di NGL). Fonte: Wikipedia Immages</p>
    <model-viewer
            src={koldy}
            alt="A 3D model"
            auto-rotate
            camera-controls
            ar
            style={{ width: '100%', height: '500px' }}
        ></model-viewer>
    <p>Esempio di modello 3d con texture, che si può pure roteare!</p>
  </Col>
</Row>
    
    
    <h2>Programmazione</h2>
    
    <p>
        Ora, dopo aver creato le componenti grafiche di un videogioco, bisogna passare al <strong>nucleo del videogioco</strong>, ovvero il <strong>motore grafico</strong>. Mediante ciò, gli sviluppatori delle case videoludiche possono integrare i vari componenti grafici e, tramite la programmazione, personalizzarli (non graficamente ma a livello di <strong>meccaniche</strong>), programmando così anche le <strong>interfacce grafiche (GUI)</strong>.
    </p>
    <p>
        Vi sono <strong>engine pubblici</strong>, come <strong>Unity</strong> o <strong>Unreal Engine</strong> (il motore grafico per eccellenza e usato da Fortnite), che potete usare anche voi se un giorno desideraste ideare un videogioco. Oppure, potete scrivere il motore grafico da zero (che, come immaginate, è un pelino più difficoltoso, ma solo un pelino!).
    </p>
    <p>
        A livello di <strong>linguaggi di programmazione</strong>, dovrete imparare il <strong>C++</strong> o il <strong>C#</strong> se vorrete creare videogiochi multiplatform. Se invece vorrete creare giochi web-based, potrete usare <strong>JavaScript</strong> (il linguaggio, insieme ad HTML, usato per creare questo sito web ma anche tutti gli altri). Poi potrete usare <strong>Java</strong> (usato da Minecraft PC) o <strong>Python</strong>, ma non sono adatti a giochi multiplatform.
    </p>
    <p>
        Il <strong>motore grafico</strong> è il nucleo software di un videogioco o di qualsiasi altra applicazione con grafica in tempo reale. Esso fornisce le tecnologie di base, semplifica lo sviluppo e spesso permette al gioco di funzionare su piattaforme differenti come le console o sistemi operativi per personal computer. La funzionalità di base fornita tipicamente da un motore grafico include un motore di rendering per grafica 2D e 3D, un motore fisico o rilevatore di collisioni, suono, scripting, animazioni, intelligenza artificiale, networking e scene-graph.
    </p>
    <p>
        La popolarità di motori grafici come Unreal Engine e Unity è dovuta alla loro capacità di fornire strumenti potenti e flessibili che permettono agli sviluppatori di concentrarsi sulla creatività e sul design del gioco, piuttosto che sui dettagli tecnici. Questi motori offrono una vasta gamma di funzionalità, tra cui la gestione della grafica, la fisica, l'audio e molto altro, rendendo il processo di sviluppo più efficiente e accessibile.
    </p>
    <p>
        In sintesi, il motore grafico è il cuore pulsante di un videogioco, permettendo agli sviluppatori di trasformare le loro idee in realtà virtuali coinvolgenti e interattive. Che si tratti di creare mondi fantastici, personaggi dettagliati o meccaniche di gioco complesse, il motore grafico è lo strumento che rende tutto possibile.
    </p>
    <Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src='https://ue-cdn.artstation.com/imgproxy/FRRZ3IOerOGxth0w3eQG4wsA_YP11qRv4Q_bCJba9A0/filename:1_wctyxpis.jpg/resizing_type:fit/width:1280/height:720/aHR0cHM6Ly9kMWl2N2RiNDR5aGd4bi5jbG91ZGZyb250Lm5ldC92aWRlby90aHVtYm5haWxzLzk4YTNjY2VjLTNmMzItNDVkMS1iMjY1LTA5NzQzZTc1OTM4OC8xX3djdHl4cGlzLmpwZw' rounded fluid />
    <p>Schermata di Unreal Engine. Fonte: Epic Games</p>
  </Col>
</Row>

    
    <h2>Distribuzione</h2>
    <p>
        Dopo aver <strong>programmato il gioco</strong>, testato varie volte e corretto gli eventuali bug, è ora di <strong>distribuirlo</strong> a tutti. In questo momento, il codice dei videogiochi viene <strong>compilato in linguaggio macchina</strong> (ovvero in una lingua comprensibile da tutti i dispositivi), in base alla piattaforma di destinazione.
    </p>
    <p>
        I videogiochi possono essere distribuiti attraverso vari canali. Tra i più comuni ci sono il <strong>proprio sito web</strong>, gli <strong>store ufficiali</strong> come <strong>Steam</strong>, o gli store dei vari sistemi operativi come <strong>Google Play</strong> per Android e <strong>App Store</strong> per iOS. Inoltre, ci sono i vari store delle console.
    </p>
    <p>
        Meno frequentemente, i videogiochi possono essere distribuiti usando <strong>dischi fisici</strong> (come accadeva con le vecchie PlayStation e Xbox) o <strong>cartucce</strong> (tipo la Nintendo Switch). Dopo averlo distribuito, si può dire che la fase di creazione del videogioco è finita, e quindi ora esiste veramente!
    </p>
    <p>
        La distribuzione è una fase cruciale che richiede una pianificazione attenta. Gli sviluppatori devono considerare il pubblico di destinazione e scegliere i canali di distribuzione più adatti per raggiungerlo. Inoltre, devono assicurarsi che il gioco sia ottimizzato per ogni piattaforma, garantendo un'esperienza di gioco fluida e coinvolgente.
    </p>
    <p>
        La distribuzione digitale ha rivoluzionato il modo in cui i videogiochi vengono venduti e giocati. Piattaforme come Steam, Google Play e App Store offrono una vasta gamma di giochi accessibili con un semplice clic, rendendo più facile per i giocatori scoprire e acquistare nuovi titoli. Tuttavia, la distribuzione fisica mantiene ancora un fascino particolare, soprattutto per i collezionisti e gli appassionati di edizioni speciali.
    </p>
    
    <h1>Esiste un gioco in particolare dove sono gli stessi utenti a creare giochi.</h1>
    <p>
        Ebbene sì, esiste un gioco dove in realtà sono gli stessi utenti a progettare le mappe, le proprie meccaniche ed esprimere le proprie idee, e lo puoi fare pure tu stesso. Sto parlando di <strong>Roblox</strong>, che grazie al suo engine <strong>Roblox Studio</strong>, permette a chiunque di diventare un creatore di giochi.
    </p>
    <p>
        Con una minima conoscenza del <strong>linguaggio di scripting Lua</strong> e facili esercizi di allenamento alla creazione, puoi creare il tuo gioco che venga distribuito su tutte le piattaforme senza che tu debba occupartene! Roblox Studio è un <strong>ambiente di sviluppo integrato (IDE)</strong> che offre strumenti potenti per la creazione di giochi 3D.
    </p>
    <p>
        Roblox Studio ti permette di creare quasi tutto ciò che puoi immaginare, combinando parti e mesh in modelli con materiali integrati o texture personalizzate. Puoi anche sovrapporre o integrare componenti dell'interfaccia utente come etichette, pulsanti e campi di testo per fornire più modi per gli utenti di interagire con le tue creazioni.
    </p>
    <p>
        Una delle caratteristiche più interessanti di Roblox Studio è la possibilità di <strong>pubblicare rapidamente</strong> e aggiornare i tuoi giochi. Basta un clic per pubblicare e le tue esperienze sono automaticamente disponibili su una vasta rete di utenti su una vasta gamma di dispositivi. Inoltre, puoi collaborare con altri creatori in tempo reale, rendendo il processo di sviluppo ancora più dinamico e coinvolgente.
    </p>
    <p>
        In sintesi, Roblox Studio è una piattaforma straordinaria che abbassa la barriera d'ingresso per la creazione di giochi, permettendo a chiunque di trasformare le proprie idee in realtà virtuali coinvolgenti e interattive. Che tu sia un principiante o un esperto, Roblox Studio offre tutto ciò di cui hai bisogno per iniziare a creare e condividere i tuoi giochi con il mondo.
    </p>
    
</p>
<Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src='https://upload.wikimedia.org/wikipedia/commons/7/7e/Roblox_Logo_2022.jpg' rounded fluid />
    <p>Logo di Roblox</p>
  </Col>
</Row>
<h1>Creare Mod e Server per gli altri videogiochi (Prossimamente).</h1>
</Container>
</div>
<h3 style={{borderLeft: '20px', color:'white'}}>Fonti:
    Robox Studio, NGL Cila, Epic Games e vigamusacademy.com
</h3>
<Tops />
</div>
    )
}


export default Creazionevideogiochi;