// cardUtils.js
import Parse from 'parse';

export const handleSubmit = async (e, title, text, image, buttonLink, setShowModal) => {
  e.preventDefault();

  // Caricare l'immagine su Parse
  const parseFile = new Parse.File(image.name, image);

  try {
    await parseFile.save();

    // Salvare i dati della card nel database
    const CardData = Parse.Object.extend('CardData');
    const cardData = new CardData();

    cardData.set('title', title);
    cardData.set('text', text);
    cardData.set('imageUrl', parseFile.url());
    cardData.set('buttonLink', buttonLink);

    await cardData.save();
    console.log('Card data saved successfully');

    // Mostrare il popup di conferma
    setShowModal(true);
  } catch (error) {
    console.error('Error while saving card data: ', error);
  }
};
