import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
import sfondo from './contents/sfondo.png';
import {Row, Container, Col, Image} from 'react-bootstrap';
import cannabis from './contents/Cannabis.jpg'
import mcs from './contents/mcs.webp';
import './contents/popstyle.css';
import thc from './contents/thc.png'
import cbd from './contents/cbd.png'
import marocco from './contents/marocco.webp'

function Cannabis () {
    const ChemicalFormula = ({ formula }) => {
        return (
          <span>
            {formula.split(/(\d+)/).map((part, index) =>
              /\d+/.test(part) ? <sub key={index}>{part}</sub> : part
            )}
          </span>
        );
      };
    return (
     <div style={{backgroundImage: `url(${sfondo})` }}>
        <Navbarngl />
        
        <h1 style={{fontstyle: 'bold', fontFamily: 'Impact', fontSize: '60px', color: 'white'}}>NGLPop</h1>
        <div  className='div-centrale' style={{backgroundColor: 'white'}}>
        <p style={{marginLeft: '8px', fontSize: '12px'}}>2 Luglio 2024 * 13:25 Chimica e Biochimica </p>
        <Container>
       <h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> CANNABIS: COSA SUCCEDE AL TUO CORPO?</h1>
       <h2 style={{  marginLeft: '15px'}}> Cos'è la Cannabis? </h2>
       <p style={{marginLeft: '10px'}}>La <strong>Cannabis è più di una semplice pianta</strong>: è un fenomeno globale. Cresce con <strong> disinvoltura nelle zone temperate e si espande fino ai tropici</strong>, mostrando una<strong>notevole capacità di adattamento.</strong>  È diventata una delle <strong>piante più riconosciute</strong>, al pari di giganti come tabacco, alcol e caffeina. <br /> Ma la sua fama non si ferma all’uso contemporaneo; la cannabis <strong>ha radici profonde nella storia</strong>, essendo stata una risorsa chiave per la produzione di fibra duratura. <br />

Nel linguaggio di oggi, le parti della pianta sono identificate come<strong> ‘marijuana’,</strong> per l’erba, e <strong>‘hashish’</strong>, per la resina. <br /> Questi termini sono entrati nel vocabolario quotidiano, evidenziando la presenza onnipresente della pianta nella cultura moderna</p>


      <Row>
        <Col xs={6} md={4}>
          <Image src={cannabis} rounded />
          
        </Col>
</Row>

<p style={{marginLeft: '10px'}}>Pianta da Cannabis. Fonte: Wikipedia Images</p>
<h2 style={{  marginLeft: '15px'}}> Perchè la Cannabis fa male? </h2>
<p style={{marginLeft: '10px'}}>La causa non è la pianta in se per sè, ma bensì dalle <strong>sostanze contenute (in particolar modo THC e CBD)</strong> . Immagina di essere in un laboratorio scientifico, dove ogni molecola <strong> ha il suo segreto</strong>. Qui incontriamo due sostanze intriganti: THC e CBD. <strong>Entrambi hanno la stessa formula chimica (<ChemicalFormula formula="C21H30O2" />) , ma è il modo in cui “accordano”</strong> i loro atomi che crea effetti diversi. (Struttura molecolare differente)
Il THC è <strong> come un rocker che sa come far vibrare la folla.</strong> Si lega ai recettori <strong>CB1</strong> nel nostro sistema endocannabinoide, proprio come un amplificatore collegato a una chitarra elettrica, e quando inizia a suonare,<strong> cambia il ritmo dei neurotrasmettitori</strong>, portando a un’esperienza psicotropa intensa.

Il CBD, d’altra parte,<strong> è come un chitarrista acustico che suona melodie rilassanti.</strong> Non si lega direttamente agli "amplificatori" (recettori) CB1, ma influisce sul sistema in modi più delicati, come un musicista che suona in una sala da concerto, offrendo una sensazione di calma e benessere.

In sostanza, il THC e il CBD sono come due versioni di una canzone: una rock elettrizzante e l’altra acustica e tranquilla, entrambe con lo stesso testo ma con melodie che toccano corde diverse nell’ascoltatore.</p>
<Container>
      <Row>
        <Col xs={6} md={4}>
          <Image src={thc} rounded />
          <p>Str. mol. THC. Fonte: Wikipedia images</p>
        </Col>
        <Col xs={6} md={4}>
          <Image src={cbd} roundedCircle />
          <p>Str. mol. CBD. Fonte: Wikipedia images</p>
        </Col>
      </Row>
    </Container><p> Se il THC si lega al cervello, avvengono effetti (interferenze) sulla coordinazione motoria e sul movimento. Se si lega ai recettori presenti sullo stomaco e sull'intestino , dato che gli "amplificatori" CB1 sono presenti in tutto il corpo, stimolano il rilascio di grelina che fa' aumentare l'appetito, dando l'effetto di "fame chimica". Mentre può influire nell'apparato cardiovascolare, cambiando così il ritmo del battito cardiaco.</p>
<Col xs={6} md={4}>
          <Image src={mcs} rounded style={{width: '240px', height: '150px'}} />
          <p>THC che si lega a CBD. Fonte: Geopop</p>
          
        </Col>

      <h2 style={{marginLeft: '15px'}}>Dove si coltiva più Cannabis?</h2>
      <p style={{marginLeft: '10px'}}>
  Ehi ragazzi, avete mai pensato a quale paese sia il <strong>re del verde</strong>? 🌿 No, non sto parlando di giardinaggio, ma di qualcosa di un po’ più… <strong>speziato</strong>! Parliamo di hashish e di dove viene coltivato di più.

  Sul <strong>terzo gradino del podio</strong>, abbiamo l’<strong>Afghanistan</strong>. Questo posto è un veterano quando si tratta di coltivare la famosa pianta verde, con ben <strong>20.000 ettari</strong> di terra dedicati! È come se avessero il loro piccolo giardino segreto, ma su scala industriale!

  Ora, la <strong>medaglia d’argento</strong> va al <strong>Messico</strong>. Questi ragazzi sono sempre stati dei pesi massimi nella produzione, e nel 2021 hanno deciso di giocare secondo le regole, iniziando a regolamentare tutto. Questo potrebbe farli diventare i <strong>numero uno al mondo</strong>!

  Ma aspettate, chi è in cima alla classifica? Il <strong>Marocco</strong>! Con circa <strong>57.000 ettari</strong> di terra e una produzione stellare di <strong>700 tonnellate</strong>, sono i campioni indiscussi di questa coltura.

  Quindi, la prossima volta che pensate a questi paesi, ricordate che non sono solo posti con cibo fantastico e belle spiagge, ma sono anche dei veri e propri <strong>giganti verdi</strong>! 🏆🌱 (Dati presi dall'Ufficio delle Nazioni Unite contro la droga e il crimine)
</p>
<Col xs={6} md={4}>
          <Image src={marocco} rounded />
          <p>Coltivazione di Cannabis in Marocco</p>
        </Col>

        </Container>
</div>
<h3 style={{borderLeft: '20px', color: 'white'}}>Fonti:
    Geopop.it , Ministero dell'Interno e Metallirari.com
</h3>
       <Tops />
     </div>
    );
}

export default Cannabis;