import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
function NotizieVige ()
{
    return(
        <div>
        <Navbarngl />
        <Tops />
        </div>
    )
}
export default NotizieVige