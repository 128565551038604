import React from "react";
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { FaInstagram, FaGamepad, FaWhatsapp, FaTiktok } from 'react-icons/fa';
import { ReactComponent as Ngllogo } from './contents/nglicon.svg';
import ngllogo from './contents/ngllogo.jpg';
import styled, { keyframes, css } from 'styled-components';

const slideDown = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
`;

const scaleUp = keyframes`
  0% { transform: scale(1) rotate(0deg); }
  100% { transform: scale(1.2) rotate(360deg); }
`;

const AnimatedNavbar = styled(Navbar)`
  animation: ${slideDown} 1s ease-in-out;
  background-color: #007bff; /* Azzurro */
`;

const SocialIcon = styled(Nav.Link)`
  transition: transform 0.3s ease-in-out;
  &:hover {
    animation: ${scaleUp} 0.5s ease-in-out forwards;
  }

  /* Disabilita l'animazione su dispositivi mobili */
  @media (max-width: 768px) {
    &:hover {
      animation: none;
    }
  }
`;

function Navbarngl() {
    return (
        <AnimatedNavbar collapseOnSelect expand="lg" style={{ fontFamily: 'Inter, sans-serif' }} variant="dark">
            <Container>
                <Navbar.Brand href="#home" aria-label="Home">
                    <img
                        alt="NGL Cila Logo"
                        src={ngllogo}
                        width="60"
                        height="60"
                        className="d-inline-block align-top"
                        onContextMenu={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Navbar.Text className="text-white fs-1 fw-bold d-none d-lg-block">
                            Ngl Cila
                        </Navbar.Text>
                    </Nav>
                    <Nav className="ms-auto">
                        <Nav.Link href="/home" style={{ color: 'white' }} aria-label="Home section">Home</Nav.Link>
                        <Nav.Link href="/news" style={{ color: 'white' }} aria-label="Home section">News</Nav.Link>
                        <Nav.Link href="/archiviofoto" style={{ color: 'white' }} aria-label="Home section">Galleria</Nav.Link>
                        <Nav.Link href="/eventi" style={{ color: 'white' }} aria-label="Home section">Eventi</Nav.Link>
                        <NavDropdown title="Altro" id="navbarScrollingDropdown" aria-label="Altro section" style={{  }}>
                            
                            
                            
                            <NavDropdown.Item href="/acquistomagliette">
                                Acquista qui la tua maglietta
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/orarioautoguidovie">
                                Orario linea 106 Autoguidovie
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/download" aria-label="Download app">Scarica l'app di NGL Cila</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Altri nostri progetti" id="navbarScrollingDropdown" aria-label="Altri nostri progetti section" style={{ color: 'white' }}>
                            <NavDropdown.Item href="https://roggiafanpage.nglcila.com" aria-label="Roggia Fanpage">Roggia Fanpage</NavDropdown.Item>
                            <NavDropdown.Item href="/cilastudios" aria-label="Visualizzatore modelli minecraft">Cila Studios</NavDropdown.Item>
                            <NavDropdown.Item href="/ciclovie" aria-label="Ciclovie di NGL Cila">Ciclovie di NGL Cila</NavDropdown.Item>
                            
                        </NavDropdown>
                    </Nav>
                    <Nav className="ms-3">
                        <SocialIcon href="https://www.instagram.com/ngl_cila" aria-label="Instagram">
                            <FaInstagram size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="https://whatsapp.com/channel/0029VajF80s6BIEcButOf50E" aria-label="WhatsApp">
                            <FaWhatsapp size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="https://www.tiktok.com/@ngl_cila?_t=8pip3VNfjG4&_r=1" aria-label="Tik Tok">
                            <FaTiktok size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="/minigioco" aria-label="Minigioco">
                            <FaGamepad size={30} color="white" />
                        </SocialIcon>
                        <SocialIcon href="https://www.ngl.link/ngl_cila2" aria-label="NGL">
                            <Ngllogo width="30" height="30" fill="white" />
                        </SocialIcon>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </AnimatedNavbar>
    );
}

export default Navbarngl;


































