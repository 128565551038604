// photoFormUtils.js
import Parse from 'parse';

export const handleSubmit = async (e, description, image, setMessage, setShowAlert, onPhotoAdded) => {
    e.preventDefault();

    // Caricare l'immagine su Parse
    const parseFile = new Parse.File(image.name, image);

    try {
        await parseFile.save();

        // Salvare i dati della foto nel database
        const PhotoData = Parse.Object.extend('PhotoData');
        const photoData = new PhotoData();

        photoData.set('description', description);
        photoData.set('imageUrl', parseFile.url());

        await photoData.save();
        console.log('Photo data saved successfully');

        // Mostrare messaggio di successo
        setMessage('La foto è stata caricata con successo');
        setShowAlert(true);

        // Notificare il componente padre che una nuova foto è stata aggiunta
        onPhotoAdded();
    } catch (error) {
        console.error('Error while saving photo data: ', error);
        // Mostrare messaggio di errore
        setMessage('Errore nel caricamento della foto');
        setShowAlert(true);
    }
};
