import React, { useState, useEffect } from 'react';
import { FaSun, FaCloud, FaCloudRain, FaSnowflake, FaBolt, FaClock } from 'react-icons/fa';
import axios from 'axios';

const WeatherWidget = () => {
  const [weather, setWeather] = useState({ temp: '', description: '' });
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=Cilavegna&appid=1255c3c6f0e7295af1edf3d18808d0b7&units=metric`);
        const data = response.data;
        setWeather({
          temp: Math.round(data.main.temp),
          description: data.weather[0].description
        });
      } catch (error) {
        console.error("Errore nel recupero dei dati meteo", error);
      }
    };

    fetchWeather();

    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getWeatherIcon = (description) => {
    switch (description) {
      case 'clear sky':
        return <FaSun color="gold" />;
      case 'few clouds':
      case 'scattered clouds':
      case 'broken clouds':
        return <FaCloud color="lightgray" />;
      case 'shower rain':
      case 'rain':
        return <FaCloudRain color="blue" />;
      case 'thunderstorm':
        return <FaBolt color="yellow" />;
      case 'snow':
        return <FaSnowflake color="lightblue" />;
      default:
        return <FaCloud color="lightgray" />;
    }
  };

  const translateDescription = (description) => {
    switch (description) {
      case 'clear sky':
        return 'Cielo sereno';
      case 'few clouds':
        return 'Poche nuvole';
      case 'scattered clouds':
        return 'Nuvole sparse';
      case 'broken clouds':
        return 'Nuvoloso';
      case 'shower rain':
        return 'Pioggia a tratti';
      case 'rain':
        return 'Pioggia';
      case 'thunderstorm':
        return 'Temporale';
      case 'snow':
        return 'Neve';
      default:
        return 'Nuvoloso';
    }
  };

  return (
    <div style={{ textAlign: 'center', color: 'white' }}>
      <h2>INDICAZIONI METEO</h2>
      <h3>Cilavegna (PV)</h3>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '2rem' }}>
        <span>{weather.temp}°C</span>
        <span style={{ marginLeft: '8px' }}>{getWeatherIcon(weather.description)}</span>
      </div>
      <div>{translateDescription(weather.description)}</div>
      
    </div>
  );
};

export default WeatherWidget;









