import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import '@google/model-viewer';
import Navbarngl from './navbarngl';
import Tops from './top';

const ModelViewer = () => {
  const [modelSrc, setModelSrc] = useState('');

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setModelSrc(url);
    }
  };

  return (
    <div>
        <Navbarngl />
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <Form>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Carica il tuo modello GLTF</Form.Label>
              <Form.Control type="file" accept=".gltf, .glb" onChange={handleFileUpload} />
            </Form.Group>
          </Form>
          {modelSrc && (
            <model-viewer
              src={modelSrc}
              alt="A 3D model"
              auto-rotate
              camera-controls
              style={{ width: '100%', height: '500px' }}
            ></model-viewer>
          )}
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <br />
      <br />
      
    </Container>
    <Tops />
    </div>
  );
};

export default ModelViewer;
