import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function Followecounter() {
  const [followers, setFollowers] = useState(null);
  const username = 'ngl_cila';
  const proxyUrl = 'https://api.allorigins.win/get?url=';
  const targetUrl = `https://www.instagram.com/${username}/`;

  const fetchFollowers = async () => {
    try {
      const response = await axios.get(proxyUrl + encodeURIComponent(targetUrl));
      const html = response.data.contents;
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
      const followers = doc.querySelector('span.g47SY').innerText;
      setFollowers(followers);
    } catch (error) {
      console.error("Errore nel recupero dei followers:", error);
    }
  };

  useEffect(() => {
    fetchFollowers();
    const interval = setInterval(fetchFollowers, 60000); // Intervallo di 1 minuto
    return () => clearInterval(interval);
  }, []);

  return (
    <Container className="mt-5 text-center">
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Card className="bg-dark text-white">
            <Card.Body>
              <Card.Title>Instagram Follower Tracker</Card.Title>
              <Card.Text>
                <strong>{username}</strong> ha {followers} followers.
              </Card.Text>
              <Button variant="primary" onClick={fetchFollowers}>Aggiorna Followers</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <style jsx>{`
        body {
          background-color: #001f3f;
          color: white;
        }

        .card {
          margin-top: 20px;
        }

        .card-title, .card-text {
          color: white;
        }
      `}</style>
    </Container>
  );
}

export default Followecounter;










