// PhotoForm.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { FaImage, FaAlignLeft } from 'react-icons/fa';
import { handleSubmit } from './js/photoutilss';

function PhotoForm({ onPhotoAdded }) {
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    return (
        <div>
            <Container className="mt-5">
                <Row className="justify-content-center">
                    <Col md={6}>
                        {showAlert && <Alert variant={message.includes('successo') ? 'success' : 'danger'}>{message}</Alert>}
                        <Form onSubmit={(e) => handleSubmit(e, description, image, setMessage, setShowAlert, onPhotoAdded)}>
                            <Form.Group controlId="formDescription">
                                <Form.Label><FaAlignLeft /> Descrizione</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formImage">
                                <Form.Label><FaImage /> Immagine</Form.Label>
                                <Form.Control
                                    type="file"
                                    onChange={(e) => setImage(e.target.files[0])}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-3">
                                Salva
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default PhotoForm;


