// sidebarUtils.js
import Parse from 'parse';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

export const handleSubmit = async (value, review) => {
  const Review = Parse.Object.extend('Review');
  const reviewObject = new Review();
  reviewObject.set('rating', value);
  reviewObject.set('reviewText', review);

  try {
    await reviewObject.save();
    alert('La tua recensione è stata registrata');
  } catch (error) {
    console.error('Errore durante la registrazione: ', error);
    alert('Registrazione della recensione fallita');
  }
};

export const renderStars = (value, setValue) => {
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= value) {
      stars.push(<FaStar key={i} onClick={() => setValue(i)} color="gold" size="2em" />);
    } else if (i === Math.ceil(value) && !Number.isInteger(value)) {
      stars.push(<FaStarHalfAlt key={i} onClick={() => setValue(i)} color="gold" size="2em" />);
    } else {
      stars.push(<FaRegStar key={i} onClick={() => setValue(i)} size="2em" />);
    }
  }
  return stars;
};

