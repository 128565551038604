// ReviewWidget.js
import React, { useState } from 'react';
import { renderStars, handleSubmit } from './js/sidebarutils';

const ReviewWidget = () => {
  const [value, setValue] = useState(2);
  const [review, setReview] = useState('');

  return (
    <div style={{ width: '100%', padding: 24, borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', textAlign: 'center', backgroundColor: '#001f3f', color: 'white', fontFamily: 'Inter, sans-serif' }}>
      <h2>Offri una recensione a NGL CILA</h2>
      <div style={{ marginTop: 16, display: 'flex', justifyContent: 'center' }}>
        {renderStars(value, setValue)}
      </div>
      <textarea
        rows={4}
        placeholder="Scrivi una recensione"
        value={review}
        onChange={(e) => setReview(e.target.value)}
        style={{ width: '100%', marginTop: 16, padding: 8, borderRadius: 4, border: '1px solid #ccc', fontFamily: 'Inter, sans-serif', color: 'black' }}
      />
      <button
        onClick={() => handleSubmit(value, review)}
        style={{ marginTop: 16, padding: '10px 20px', backgroundColor: 'white', borderColor: 'black', color: 'black', fontWeight: 'bold', borderRadius: 4, cursor: 'pointer', fontFamily: 'Inter, sans-serif' }}
      >
        Invia
      </button>
    </div>
  );
};

export default ReviewWidget;




