import React from "react";
import sfondo from './contents//sfondo.png';
import { Container, Row, Col, Image } from "react-bootstrap";
import Tops from "./top";
import vulcano from './contents/vulcano.avif'
import Navbarngl from "./navbarngl";
import oro from './contents/erebus-oro.avif';
function Eruzioneoro ()

{
    return(
        
 <div style={{backgroundImage: `url(${sfondo})` }}>
    <Navbarngl />
 <h1 style={{fontstyle: 'bold', fontFamily: 'Impact', fontSize: '60px', color: 'white'}}>NGLPop</h1>
 <div  className='div-centrale' style={{backgroundColor: 'white'}}>
        <p style={{marginLeft: '8px', fontSize: '12px'}}>2 Luglio 2024 * 13:25 Scienza</p>
<Container>
<h1 style={{ fontFamily: 'Arial Black', marginLeft: '8px', fontsize: '16px'}}> ANTARTIDE: IL VULCANO CHE ERUTTA ORO OGNI GIORNO!</h1>
<p style={{marginLeft: '10px'}}>
  Nell’isolata e incontaminata <strong>Antartide</strong>, tra i suoi <strong>nove vulcani attivi</strong>, si nasconde un segreto scintillante. Uno di questi vulcani è una fonte sorprendente di <strong>particelle d’oro metallico</strong>, che vengono rilasciate insieme ai gas vulcanici. Questo incredibile fenomeno, che arricchisce l’atmosfera con un valore stimato di <strong>\$6000 al giorno</strong>, è il risultato di una particolare <strong>composizione chimica del magma</strong> e di un <strong>processo eruttivo</strong> che sembra fatto apposta per stupirci.
</p>
<Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src={vulcano} rounded fluid />
  </Col>
</Row>
<p style={{marginLeft: '10px'}}>Monte Erebus. Fonte: NASA</p>
<h2 style={{  marginLeft: '15px'}}> Il vulcano si chiama Monte Erebus </h2>
<p style={{marginLeft: '10px'}}>
  Di fatto il <strong>Monte Erebus</strong> non si limita a eruttare lava, ma anche <strong>particelle d’oro</strong>! Queste particelle sono così minuscole che potrebbero passare inosservate, ma insieme hanno un peso notevole.

  Questo oro viene dal profondo della Terra, da un magma che è come una miscela speciale piena di metalli come l’<strong>oro</strong>, lo <strong>zinco</strong> e il <strong>rame</strong>. Quando questo magma arriva in superficie e incontra l’aria gelida, i gas si raffreddano velocemente e l’oro si trasforma in piccoli frammenti solidi.

  Un geochimico famoso, <strong>Philip Kyle</strong>, ha scoperto che questi frammenti si formano proprio sulla lava calda. Il gas esce lentamente, e questo dà tempo all’oro di formarsi prima di essere portato via dal vento. È un fenomeno naturale incredibile che ci mostra come la Terra sia piena di sorprese e ci aiuta a imparare di più su come funziona il nostro pianeta.
</p>
<h2 style={{  marginLeft: '15px'}}> Ci sono altri vulcani che eruttano oro </h2>
<p style={{marginLeft: '10px'}}>
  Il <strong>Monte Erebus</strong> non è l'unico: anche l'<strong>Etna</strong> emette particelle d'oro. Ma sull'Etna non si trovano cristalli perché i gas si raffreddano troppo velocemente e la quantità di ceneri impedisce di vedere l'oro nell'aria.

</p>
<Row>
  <Col xs={12} sm={6} md={4} lg={3}>
    <Image src={oro} rounded fluid />
  </Col>
</Row>
<p>Fonte: Geopop</p>
<h2 style={{  marginLeft: '15px'}}> La domanda è: E' possibile estrarre questo oro </h2>
<p style={{marginLeft: '10px'}}> Purtroppo la dimensione delle particelle e la loro particolare dispersione nell'ambinente, ci impediscono di ricavare quest'oro in qualsiasi maniera.</p>





</Container>




    
        </div>
        <h3 style={{borderLeft: '20px'}}>Fonte:
    Geopop.it 
</h3>
        <Tops />

    
 </div>
    );
}

export default Eruzioneoro;