// ordiniUtils.js
import Parse from "parse";

export const fetchOrdini = async (setOrdini) => {
    const Acquisto = Parse.Object.extend('Acquisto');
    const query = new Parse.Query(Acquisto);
    try {
        const results = await query.find();
        setOrdini(results.map(result => result.toJSON()));
    } catch (error) {
        console.error('Errore nel recupero degli ordini:', error);
    }
};
