// accessDataUtils.js
import Parse from 'parse';

export const fetchAccessData = async (setAccessData) => {
  const AccessData = Parse.Object.extend("AccessData");
  const query = new Parse.Query(AccessData);
  try {
    const results = await query.find();
    setAccessData(results);
  } catch (error) {
    console.error('Errore durante il recupero dei dati di accesso:', error);
  }
};

export const fetchBannedIPs = async (setBannedIPs) => {
  const BannedIPs = Parse.Object.extend("BannedIPs");
  const query = new Parse.Query(BannedIPs);
  try {
    const results = await query.find();
    setBannedIPs(results.map(result => result.get('ipAddress')));
  } catch (error) {
    console.error('Errore durante il recupero degli IP bannati:', error);
  }
};

export const handleConfirmBan = async (currentIP, banReason, banDuration, banExpiryDate, setBannedIPs, bannedIPs, setShowModal, setBanReason, setBanDuration, setBanExpiryDate) => {
  const BannedIPs = Parse.Object.extend("BannedIPs");
  const bannedIP = new BannedIPs();
  bannedIP.set("ipAddress", currentIP);
  bannedIP.set("reason", banReason);
  if (banDuration === 'temporary') {
    bannedIP.set("expiryDate", banExpiryDate);
  }
  try {
    await bannedIP.save();
    setBannedIPs([...bannedIPs, currentIP]);
    setShowModal(false);
    setBanReason('');
    setBanDuration('permanent');
    setBanExpiryDate(null);
  } catch (error) {
    console.error('Errore durante il ban dell\'IP:', error);
  }
};

export const handleUnban = async (ip, setBannedIPs, bannedIPs) => {
  const BannedIPs = Parse.Object.extend("BannedIPs");
  const query = new Parse.Query(BannedIPs);
  query.equalTo("ipAddress", ip);
  try {
    const result = await query.first();
    if (result) {
      await result.destroy();
      setBannedIPs(bannedIPs.filter(bannedIP => bannedIP !== ip));
    }
  } catch (error) {
    console.error('Errore durante lo sbannamento dell\'IP:', error);
  }
};
