import React from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const SignalTable = () => {
  const signals = [
    {
      type: 'Pericolo',
      description: 'Segnali che indicano un pericolo imminente, come attraversamenti pericolosi o curve strette.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Italian_traffic_signs_-_attraversamento_pericoloso.svg" alt="Pericolo" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
    {
      type: 'Divieto',
      description: 'Segnali che vietano determinate azioni, come il divieto di accesso o di sosta.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/Italian_traffic_signs_-_senso_vietato.svg" alt="Divieto" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
    {
      type: 'Obbligo',
      description: 'Segnali che indicano un obbligo da rispettare, come la direzione obbligatoria o l’uso di dispositivi di sicurezza.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/4/45/Italian_traffic_signs_-_direzione_obbligatoria_dritto.svg" alt="Obbligo" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
    {
      type: 'Indicazione',
      description: 'Segnali che forniscono informazioni utili, come indicazioni stradali o parcheggi.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/c/c3/Italian_traffic_signs_-_icona_parcheggio.svg" alt="Indicazione" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
    {
      type: 'Segnali di prescrizione',
      description: 'Segnali che regolano il comportamento degli utenti della strada, come dare precedenza o fermarsi.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/8/89/Italian_traffic_signs_-_dare_precedenza.svg" alt="Prescrizione" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
    {
      type: 'Segnali di indicazione strade e luoghi',
      description: 'Segnali che indicano un luogo o una strada',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/Italian_traffic_signs_-_direzione_verde.svg" alt="Precedenza" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
    {
      type: 'Segnali di pericolo temporaneo',
      description: 'Segnali che indicano pericoli temporanei, come lavori in corso o incidenti.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Italian_traffic_signs_-_lavori.svg" alt="Pericolo Temporaneo" width="70" height="70" draggable="false" onClick={(e) => e.preventPrevent()} />,
    },
    {
      type: 'Segnaletica complementare',
      description: 'Segnali che forniscono informazioni aggiuntive per la sicurezza stradale, come delineatori di margine, segnalatori di ostacoli, e isole di traffico.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/8/87/Italian_traffic_signs_-_delineatori_di_margine_-_doppio_senso.svg" alt="Segnaletica Complementare" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
    {
      type: 'Semafori',
      description: 'Dispositivi luminosi che regolano il flusso del traffico agli incroci e in altre situazioni stradali.',
      icon: (
        <div>
          <i class="fa-solid fa-traffic-light"></i>
        </div>
      ),
    },
    {
      type: 'Delineatori di curve',
      description: 'Segnali che indicano la presenza di curve pericolose.',
      icon: <img src="https://upload.wikimedia.org/wikipedia/commons/0/0d/Italian_traffic_signs_-_delineatore_modulare_di_curva.svg" alt="Delineatori di Curve" width="70" height="70" draggable="false" onClick={(e) => e.preventDefault()} />,
    },
  ];

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th style={{ fontSize: '1.5em' }}>Tipologia di Segnale</th>
          <th style={{ fontSize: '1.5em' }}>Descrizione</th>
          <th style={{ fontSize: '1.5em' }}>Icona</th>
        </tr>
      </thead>
      <tbody>
        {signals.map((signal, index) => (
          <tr key={index}>
            <td style={{ fontSize: '1.2em' }}>{signal.type}</td>
            <td style={{ fontSize: '1.2em' }}>{signal.description}</td>
            <td>{signal.icon}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SignalTable;





