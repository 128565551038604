import React, { useEffect, useState } from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const Navorario = () => {
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
      let timeString = now.toLocaleDateString('it-IT', options);
      timeString = timeString.charAt(0).toUpperCase() + timeString.slice(1);
      const timeParts = timeString.split(' alle ');
      const datePart = timeParts[0];
      const timePart = timeParts[1];
      setCurrentTime(
        <>
          <span className="Navorario"><FaCalendarAlt /> {datePart}</span> <span className="Navorario"><FaClock /> {timePart}</span>
        </>
      );
    };

    updateTime();
    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Navbar bg="primary" variant="dark">
      <Container>
        <Navbar.Brand className="d-none d-md-block">{currentTime}</Navbar.Brand>
        <Navbar.Brand className="d-block d-md-none" style={{ fontSize: '0.8rem' }}>{currentTime}</Navbar.Brand>
      </Container>
      <style jsx>
        {`
         .Navorario {
  display: flex;
  align-items: center;
}

.Navorario svg {
  margin-right: 5px;
}

        `}
      </style>
    </Navbar>
  );
};

export default Navorario;




