// gameUtils.js
export const generateFood = () => {
    const foodArray = [];
    for (let i = 0; i < 15; i++) {
      foodArray.push({ top: Math.floor(Math.random() * 290), left: Math.floor(Math.random() * 290) });
    }
    return foodArray;
  };
  
  export const generateGhosts = () => {
    const ghostsArray = [];
    for (let i = 0; i < 5; i++) {
      ghostsArray.push({ top: Math.floor(Math.random() * 290), left: Math.floor(Math.random() * 290) });
    }
    return ghostsArray;
  };
  
  export const handleKeyDown = (e, setPosition) => {
    switch (e.key) {
      case 'ArrowUp':
        setPosition((prev) => ({ ...prev, top: Math.max(prev.top - 10, 0) }));
        break;
      case 'ArrowDown':
        setPosition((prev) => ({ ...prev, top: prev.top + 10 }));
        break;
      case 'ArrowLeft':
        setPosition((prev) => ({ ...prev, left: Math.max(prev.left - 10, 0) }));
        break;
      case 'ArrowRight':
        setPosition((prev) => ({ ...prev, left: prev.left + 10 }));
        break;
      default:
        break;
    }
  };
  
  export const handleArrowClick = (direction, setPosition) => {
    switch (direction) {
      case 'up':
        setPosition((prev) => ({ ...prev, top: Math.max(prev.top - 10, 0) }));
        break;
      case 'down':
        setPosition((prev) => ({ ...prev, top: prev.top + 10 }));
        break;
      case 'left':
        setPosition((prev) => ({ ...prev, left: Math.max(prev.left - 10, 0) }));
        break;
      case 'right':
        setPosition((prev) => ({ ...prev, left: prev.left + 10 }));
        break;
      default:
        break;
    }
  };
  
  export const resetGame = (setPosition, setScore, setFood, setGhosts, setGhostSpeed, setShowGameOver, setShowVictory) => {
    setPosition({ top: 0, left: 0 });
    setScore(0);
    setFood(generateFood());
    setGhosts(generateGhosts());
    setGhostSpeed(1000);
    setShowGameOver(false);
    setShowVictory(false);
  };
  