import React, { useEffect, useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import Parse from 'parse';
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const PARSE_APPLICATION_ID = 'h6n3hXeAyPq4Q9VYaiI1n5FnRIxoeCdlrynUbAMA';
const PARSE_JAVASCRIPT_KEY = 'Ts74nlTiApt0TLezItum716SuEnWEIsujWFpjsct';
const PARSE_HOST_URL = 'https://parseapi.back4app.com';

Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

function ReviewCard() {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const Review = Parse.Object.extend('Review');
      const query = new Parse.Query(Review);
      try {
        const results = await query.find();
        setReviews(results);
      } catch (error) {
        console.error('Error while fetching reviews: ', error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: '100vh', backgroundColor: '#f5f5f5', padding: '2rem' }}>
      <Card style={{ width: '90%', maxWidth: '600px', maxHeight: '80vh', overflowY: 'auto', padding: '2rem', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px', backgroundColor: '#001f3f', color: 'white', borderBottom: '5px solid #ccc' }}>
        <Card.Body>
          <Card.Title className="text-center mb-4" style={{ color: 'white' }}>Recensioni</Card.Title>
          {reviews.map((review) => (
            <div key={review.id} style={{ marginBottom: '1rem', borderBottom: '1px solid #ccc', paddingBottom: '1rem' }}>
              <Card.Subtitle className="mb-2" style={{ color: 'white' }}>
                <Rating
                  readonly
                  initialRating={review.get('rating')}
                  emptySymbol={<FaRegStar color="#ffd700" size={24} />}
                  fullSymbol={<FaStar color="#ffd700" size={24} />}
                  halfSymbol={<FaStarHalfAlt color="#ffd700" size={24} />}
                />
              </Card.Subtitle>
              <Card.Text style={{ color: 'white' }}>
                {review.get('reviewText')}
              </Card.Text>
            </div>
          ))}
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ReviewCard;




