import React, { useState, useEffect, useCallback } from 'react';
import Parse from 'parse';
import axios from 'axios';
import { Modal } from 'react-bootstrap';

const UserAccessData = ({ onAccessDataSaved }) => {
  const [location, setLocation] = useState(null);
  const [ipAddress, setIpAddress] = useState('');
  const [browserInfo] = useState({
    browserName: navigator.appName,
    browserVersion: navigator.appVersion,
    os: navigator.platform,
    language: navigator.language
  });
  const [additionalData] = useState({
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    userAgent: navigator.userAgent
  });
  const [showBanModal, setShowBanModal] = useState(false);
  const [banMessage, setBanMessage] = useState('');

  const fetchIpAddress = useCallback(async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      setIpAddress(response.data.ip);
    } catch (error) {
      console.error('Errore durante il recupero dell\'indirizzo IP:', error);
    }
  }, []);

  const saveAccessData = useCallback(() => {
    const AccessData = Parse.Object.extend("AccessData");
    const accessData = new AccessData();

    const nowUTC = new Date();
    const nowItalianTime = new Date(nowUTC.getTime());

    accessData.set("dateTime", nowItalianTime);
    if (location) {
      accessData.set("location", new Parse.GeoPoint(location.latitude, location.longitude));
    } else {
      accessData.set("location", new Parse.GeoPoint(0, 0));
    }
    accessData.set("ipAddress", ipAddress);
    accessData.set("browserInfo", JSON.stringify(browserInfo));
    accessData.set("additionalData", JSON.stringify(additionalData));

    accessData.save()
      .then((object) => {
        console.log('Dati di accesso salvati con successo:', object);
        if (onAccessDataSaved) {
          onAccessDataSaved(object);
        }
      }, (error) => {
        console.error('Errore durante il salvataggio dei dati:', error);
      });
  }, [location, ipAddress, browserInfo, additionalData, onAccessDataSaved]);

  const generateBanId = useCallback(() => {
    return Math.floor(10000 + Math.random() * 90000);
  }, []);

  const checkBannedIP = useCallback(async (ip) => {
    const BannedIPs = Parse.Object.extend("BannedIPs");
    const query = new Parse.Query(BannedIPs);
    query.equalTo("ipAddress", ip);
    try {
      const result = await query.first();
      if (result) {
        const reason = result.get('reason');
        const expiryDate = result.get('expiryDate');
        const now = new Date();
        const banId = generateBanId();
        if (expiryDate && new Date(expiryDate) < now) {
          await result.destroy();
          saveAccessData();
        } else {
          const expiryMessage = expiryDate ? `Il ban scade il: ${new Date(expiryDate).toLocaleDateString()}` : 'Ban permanente';
          setBanMessage(`
            <h1 style="color: black; font-weight: bold;">Sei stato bannato</h1>
            <p>Abbiamo sospeso il tuo account per violazione delle linee guida della community.</p>
            <p>Ban ID: ${banId}</p>
            <p>Motivazione: ${reason}</p>
            <p>${expiryMessage}</p>
          `);
          setShowBanModal(true);
        }
      } else {
        saveAccessData();
      }
    } catch (error) {
      console.error('Errore durante la verifica dell\'IP bannato:', error);
    }
  }, [generateBanId, saveAccessData]);

  useEffect(() => {
    fetchIpAddress();
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          console.error('Errore durante il recupero della geolocalizzazione:', error);
        }
      );
    } else {
      console.log('Geolocalizzazione non disponibile');
    }
  }, [fetchIpAddress]);

  useEffect(() => {
    if (ipAddress) {
      checkBannedIP(ipAddress);
    }
  }, [ipAddress, checkBannedIP]);

  return (
    <div>
      <Modal show={showBanModal} centered>
        <Modal.Header>
          <Modal.Title>
            ⚠️Accesso Negato⚠️
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: banMessage }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserAccessData;



















