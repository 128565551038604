// AddNewsForm.js
import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { FaImage, FaVideo, FaHeading, FaAlignLeft } from 'react-icons/fa';
import { handleSubmit } from './js/logicanewform';

function AddNewsForm() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [mediaType, setMediaType] = useState('image');
  const [media, setMedia] = useState(null);

  return (
    <Container>
      <Form onSubmit={(e) => handleSubmit(e, title, content, mediaType, media)}>
        <Form.Group controlId="formTitle">
          <Form.Label><FaHeading /> Titolo</Form.Label>
          <Form.Control
            type="text"
            placeholder="Inserisci il titolo"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formContent">
          <Form.Label><FaAlignLeft /> Contenuto</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Inserisci il contenuto"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="formMediaType">
          <Form.Label>Tipo di Media</Form.Label>
          <Form.Control
            as="select"
            value={mediaType}
            onChange={(e) => setMediaType(e.target.value)}
          >
            <option value="image"><FaImage /> Immagine</option>
            <option value="video"><FaVideo /> Video</option>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formMedia">
          <Form.Label>Media</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setMedia(e.target.files[0])}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Aggiungi Notizia
        </Button>
      </Form>
    </Container>
  );
}

export default AddNewsForm;

