import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
function Modulo () {
    return(
        <div >
        <Navbarngl />
        <h1 style={{color: 'white'}}>Modulo recensione</h1>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfHlxyzd9XfWT47tsFMgYbjO5N-O2ALREODzKVevxjVIj2-sA/viewform?embedded=true" width="500" height="2000" frameborder="0" marginheight="0" marginwidth="0">Caricamento…</iframe>
        <Tops /> 
        </div>
    );
}

export default Modulo;