import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";
import icona from './contents/favicon.png';
import icona2 from './contents/logospotted.jpg';

function Partnership() {
    const partners = [
        {
            name: "Roggia Fanpage",
            description: "Roggia Fanpage è un profilo Instagram che fotografa corsi d'acqua e ambienti acquatici.",
            logo: icona,
            link: "https://instagram.com/_roggia_fanpage_"
        },
        {
            name: "Spotted dell' ITIS Caramuel",
            description: "Il profilo gossip e notizie ufficiale del ITIS Caramuel di Vigevano, che collabora con noi e il nostro team.",
            logo: icona2,
            link: "https://instagram.com/spotted.caramuel_"
        }
    ];

    return (
        <Container className="my-5">
            <h1 style={{ fontFamily: 'Inter, sans-serif', animation: 'fadeInDown 1s' }}>I nostri partner</h1>
            <Row>
                {partners.map((partner, index) => (
                    <Col key={index} md={4} className="mb-4 d-flex">
                        <div className="partner-card p-3 text-center d-flex flex-column" style={{ animation: 'zoomIn 1s' }}>
                            <Image src={partner.logo} roundedCircle alt={`${partner.name} Logo`} className="mb-3 mx-auto" />
                            <h3 style={{ color: 'black' }}>{partner.name}</h3>
                            <p className="flex-grow-1" style={{ color: 'black' }}>{partner.description}</p>
                            <Button variant="primary" href={partner.link} target="_blank" className="mt-auto">
                                Visita il Profilo Instagram <FaInstagram />
                            </Button>
                        </div>
                    </Col>
                ))}
            </Row>
            <style jsx>{`
                @keyframes fadeInDown {
                    from {
                        opacity: 0;
                        transform: translateY(-20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }

                @keyframes zoomIn {
                    from {
                        opacity: 0;
                        transform: scale(0.5);
                    }
                    to {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                body {
                    background-color: #001f3f;
                    color: white;
                }

                .partner-card {
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                    display: flex;
                    flex-direction: column;
                }

                @media (max-width: 768px) {
                    .partner-card {
                        margin-bottom: 20px;
                    }
                }
            `}</style>
        </Container>
    );
}

export default Partnership;


