import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Container } from 'react-bootstrap';
import { FaBus, FaSchool } from 'react-icons/fa';
import './contents/autoguidovie.css';

const ListItem = ({ children, icons = [], schedule = "" }) => (
    <li className="list-item">
        <div>{children}</div>
        <div className="list-item-icons">
            {icons.map((icon, index) => (
                <span key={index} style={{ marginLeft: index > 0 ? '10px' : '0' }}>{icon}</span>
            ))}
        </div>
        {schedule && <div className="schedule">{schedule}</div>}
    </li>
);

function Orarioautoguidovie() {
    return (
        <div>
            <Navbarngl />
            <Container>
                <h1 style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <FaBus style={{ marginRight: '10px' }} />
                    Orario Linea 106 Autoguidovie
                </h1>
                <div className="pdf-container">
                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
                        <Viewer fileUrl="https://pavia.autoguidovie.it/files/Pavia/linee/Extraurbano/Invernale_scolastico/Linea%20106_FER%20INV%20s.pdf" />
                    </Worker>
                </div>
                <p style={{ fontFamily: 'Inter, sans-serif', fontSize: '20px' }}>
                    Le fermate effettuate da questa linea (partendo da Vigevano) sono:
                </p>
                <ul style={{ fontFamily: 'Inter, sans-serif', fontSize: '18px' }}>
                    <ListItem icons={[<FaBus />, <img src='https://reportculture.com/wp-content/uploads/2023/11/gruppo-fs-logo.png' alt="Stazione FS" style={{ width: '20px' }} />]} schedule="06.52, 07.43, 07.49, 07.50, 12.54, 13.49, 14.24, 14.27, 14.49, 15.29, 18.04, 19.00">
                        Stazione FS Mortara/Marconi (Scendete qui per IPSIA Pollini, Clerici Mortara)
                    </ListItem>
                    <ListItem schedule="07.39, 07.45, 07.46">Mortara Trento (Campo Sport) (Scendete qui per IIS A. Omodeo)</ListItem>
                    <ListItem icons={[<FaBus />]} schedule="06.45, 07.33, 07.39, 12.47, 13.42, 14.17, 14.42, 15.22, 17.57, 18.53">
                        Parona: Toma/Roma
                    </ListItem>
                    <ListItem schedule="06.44, 07.32, 07.38, 12.46, 13.41, 14.16, 14.41, 15.21, 17.56, 18.52">Parona: Dell'Offella</ListItem>
                    <ListItem schedule="06.43, 07.31, 07.37, 12.45, 13.40, 14.15, 14.40, 15.20, 17.55, 18.51">Parona: XXV Aprile/Marinino</ListItem>
                    <ListItem icons={[<FaSchool />]} schedule="06.40, 07.27, 07.33, 07.36, 12.41, 13.36, 14.11, 14.36, 15.16, 17.51, 18.47">
                        Cilavegna: Roma/II Giugno
                    </ListItem>
                    <ListItem schedule="06.38, 07.25, 07.31, 07.35, 12.40, 13.35, 14.10, 14.35, 15.15, 16.50, 17.50, 18.46">Cilavegna: Garibaldi/Cavour (Scendete qui per scuola media P.Conti)</ListItem>
                    <ListItem schedule="06.37, 07.23, 07.29, 07.33, 12.38, 13.33, 14.08, 14.33, 15.13, 16.48, 17.48, 18.44">Cilavegna: Gravellona/Vignarello</ListItem>
                    <ListItem schedule="06.33, 07.19, 07.25, 07.29, 12.34, 13.29, 14.04, 14.12, 14.29, 15.09, 16.43, 17.43, 18.40">Gravellona: Insurrezione/Delluca</ListItem>
                    <ListItem  schedule="06.29, 07.21, 07.25, 12.30, 13.25, 14.00, 14.25, 15.05, 16.37, 17.37, 18.36">
                        Vigevano: Piccolini (Casa Reclusione)
                    </ListItem>
                    <ListItem schedule="06.28, 07.19, 07.23, 12.28, 13.23, 13.58, 14.23, 15.03, 16.34, 17.34, 18.34">Vigevano: Piccolini - Gravel./Cilavegna</ListItem>
                    <ListItem schedule="06.24, 07.15, 07.19, 12.24, 13.19, 13.54, 14.19, 14.59, 16.29, 17.29, 18.29">Vigevano: Gravellona/Varese</ListItem>
                    <ListItem icons={[<FaSchool />]} schedule="06.20, 06.50, 07.15, 12.20, 13.15, 13.50, 14.15, 14.55, 16.25, 17.25, 18.25">
                        Vigevano: Sacchetti/Calzolaio d'Italia (Scendete qui per Fondazione Clerici, Fondazione Roncalli)
                    </ListItem>
                    <ListItem icons={[<FaSchool />]} schedule="06.20, 06.50, 07.15, 12.20, 13.15, 13.50, 14.15, 14.55, 16.25, 17.25, 18.25">
                        Vigevano: Ponte della Giacchetta (Scendete qui per ITIS. G Caramuel, ITI Casale, Liceo Cairoli, Roncalli, Castoldi)
                    </ListItem>
                    <ListItem schedule="06.53">Vigevano: Novara/Fossana</ListItem>
                    <ListItem schedule="06.54">Vigevano: Novara/Omegna</ListItem>
                    <ListItem schedule="07.00">Cassolnovo: M.no Conte - Gorizia, 41</ListItem>
                    <ListItem schedule="07.01">Cassolnovo: M.no Conte - Trento/Tries.</ListItem>
                    <ListItem schedule="07.04">Cassolnovo: Roma/Marconi</ListItem>
                    <ListItem schedule="07.06">Cassolnovo: C. Alberto/Cavour</ListItem>
                    <ListItem schedule="07.08">Cassolnovo: C. Alberto/Delle Noci</ListItem>
                </ul>
                <p style={{ fontFamily: 'Inter, sans-serif', fontSize: '20px' }}>
                    Le fermate effettuate da questa linea (partendo da Mortara) sono:
                </p>
                <ul style={{ fontFamily: 'Inter, sans-serif', fontSize: '18px' }}>
                    <ListItem icons={[<FaBus />, <img src='https://reportculture.com/wp-content/uploads/2023/11/gruppo-fs-logo.png' alt="Stazione FS" style={{ width: '20px' }} />]} schedule="06.55, 07.50, 08.05, 09.10, 13.10, 13.50, 14.25, 15.05, 15.10, 16.10, 18.10, 19.10">
                        Stazione FS Mortara/Marconi
                    </ListItem>
                    <ListItem schedule="13.13, 13.53, 14.28, 15.08, 15.13">Mortara Trento (Campo Sport)(Scendete qui per IIS A. Omodeo)</ListItem>
                    <ListItem schedule="07.03, 07.07, 07.58, 08.13, 09.18, 13.17, 13.58, 14.33, 15.12, 16.18, 18.18, 19.18">Parona: Toma/Roma</ListItem>
                    <ListItem schedule="07.03, 07.07, 07.58, 08.13, 09.18, 13.18, 13.58, 14.33, 15.13, 16.18, 18.18, 19.18">Parona: Dell'Offella</ListItem>
                    <ListItem schedule="07.05, 07.09, 08.00, 08.15, 09.20, 13.20, 14.00, 14.35, 15.15, 16.20, 18.20, 19.20">Parona: XXV Aprile/Carducci</ListItem>
                    <ListItem  schedule="07.08, 07.12, 08.03, 08.18, 09.23, 13.23, 14.03, 14.38, 15.18, 15.23, 16.23, 18.23, 19.23">
                        Cilavegna: Roma/II Giugno
                    </ListItem>
                    <ListItem icons={[ <FaSchool />]} schedule="07.10, 07.14, 07.14, 08.05, 08.20, 09.25, 13.27, 14.07, 14.42, 15.20, 15.25, 16.25, 16.55, 18.25, 19.25">Cilavegna: Garibaldi/Cavour(Scendete qui per scuola media P.Conti)</ListItem>
                    <ListItem schedule="07.15, 07.19, 07.19, 08.10, 08.25, 09.30, 13.32, 14.12, 14.47, 15.25, 15.30, 16.30, 17.00, 18.30, 19.30">Gravellona: Insurrezione/Delluca</ListItem>
                    <ListItem schedule="14.58, 15.41">Cassolnovo: C. Alberto/Tornura</ListItem>
                    <ListItem schedule="15.00, 15.43">Cassolnovo: C. Alberto/Cavour</ListItem>
                    <ListItem schedule="15.01, 15.44">Cassolnovo: Roma/Marconi</ListItem>
                    <ListItem schedule="15.03, 15.46">Cassolnovo: M.no Conte - Trento/Udine</ListItem>
                    <ListItem schedule="15.05, 15.48">Cassolnovo: M.no Conte - Gorizia, 41</ListItem>
                    <ListItem schedule="15.10, 15.53">Vigevano: Novara/Omegna</ListItem>
                    <ListItem schedule="15.12, 15.55">Vigevano: Novara/Valenza</ListItem>
                    <ListItem  schedule="07.19, 07.23, 07.23, 08.14, 08.29, 09.34, 13.36, 15.29, 16.34, 17.04, 18.34, 19.34">
                        Vigevano: Piccolini (Casa Reclusione)
                    </ListItem>
                    <ListItem schedule="07.20, 07.24, 07.24, 08.15, 08.30, 09.35, 13.37, 15.30, 16.35, 17.05, 18.35, 19.35">Vigevano: Piccolini - Gravel./Cilavegna</ListItem>
                    <ListItem schedule="07.22, 07.26, 07.26, 08.17, 08.32, 09.37, 13.39, 15.32, 16.37, 17.07, 18.37, 19.37">Vigevano: Gravellona/Crispiniano</ListItem>
                    <ListItem icons={[<FaSchool />]} schedule="07.30, 07.34, 07.34, 08.25, 08.40, 09.45, 13.47, 15.15, 15.40, 15.58, 16.45, 17.15, 18.45, 19.45">
                        Vigevano: Sacchetti/Calzolaio d'Italia (Scendete qui per Fondazione Clerici, Fondazione Roncalli)
                    </ListItem>
                    <ListItem  icons={[<FaBus />, <FaSchool />]}schedule="07.35, 07.40, 07.40, 08.30, 13.52">Vigevano: P.te della Giacchetta (Scendete qui per ITIS. G Caramuel, ITI Casale, Liceo Cairoli, Roncalli, Castoldi)</ListItem>
                    <ListItem icons={[<FaBus />, <img src='https://reportculture.com/wp-content/uploads/2023/11/gruppo-fs-logo.png' alt="Stazione FS" style={{ width: '20px' }} />]} schedule="08.32, 13.54">
                        Vigevano FS - IV Novembre
                    </ListItem>
                </ul>
                <p style={{ fontFamily: 'Inter, sans-serif', fontSize: '25px' }}>
                    Si noti che la linea 106, che porta al capolinea (Sacchetti/Calzolaio d'Italia), passa anche per il comune di Cassolnovo.
                </p>
            </Container>
            <Tops />
        </div>
    );
}

export default Orarioautoguidovie;










