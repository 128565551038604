// Archiviofoto.js
import React, { useState, useEffect } from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
import UserAccessData from "./geolocalizzazione";
import { fetchPhotos } from './js/photoutils';

function Archiviofoto() {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        fetchPhotos(setPhotos);
    }, []);

    return (
        <div style={{ background: 'linear-gradient(to bottom, #00008B, #87CEEB)' }} onContextMenu={(e) => e.preventDefault()}>
            <Navbarngl />
            <UserAccessData />
            <h1 style={{textAlign: 'center', fontFamily: 'Inter, sans-serif', color: 'white'}}>L'archivio fotografico di NGL cila</h1>
            <p style={{textAlign: 'center', fontFamily: 'Inter, sans-serif', color: 'white'}}>Qui trovi le foto facente parte del progetto NGLPhoto. Nota: le foto sono soggette a copyright</p>
            <div className="container">
                <div className="row">
                    {photos.map((photo) => (
                        <div key={photo.id} className="col-12 col-md-4 mb-3">
                            <img src={photo.get('imageUrl')} alt="2px" className="img-fluid" style={{ width: '100%', height: 'auto', pointerEvents: 'none' }} />
                            <p style={{color: 'white'}}>{photo.get('description')}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Tops />
        </div>
    );
}

export default Archiviofoto;




