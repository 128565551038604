// utils.js
import Parse from 'parse';

export const handleSubmit = async (e, title, content, mediaType, media) => {
  e.preventDefault();

  const News = Parse.Object.extend('News');
  const news = new News();

  const file = new Parse.File(media.name, media);

  news.set('title', title);
  news.set('content', content);
  news.set('mediaType', mediaType);
  news.set('media', file);

  try {
    await news.save();
    alert('Notizia aggiunta con successo!');
  } catch (error) {
    console.error('Errore nel salvataggio della notizia:', error);
  }
};
