import React from "react";
import Navbarngl from "./navbarngl";
import Tops from "./top";
import j62024 from './contents/j624062024.png';
import j72024 from './contents/j62024.jpg';
import j82024 from './contents/j82024.png';
import { Col, Card, Row, Container } from "react-bootstrap";
import sfondo from './contents/sfondo.png';

function Football() {
    return (
        <div style={{ background: 'linear-gradient(to bottom, #00008B, #87CEEB)', fontFamily: 'Inter, sans-serif', paddingBottom: '20px' }}>
            <Navbarngl />
            <div style={{ marginBottom: '20px' }}>
                <h1 style={{ color: 'white', textAlign: 'center' }}>Notizie sul Calcio (Olimpic 95) e Campionati nazionali e europei</h1>
            </div>
            <Container>
                <Row className="g-2">
                    <Col md={6} className="d-flex align-items-stretch"> {/* d-flex e align-items-stretch per l'allineamento */}
                        <Card style={{ width: '100%', height: 'auto' }}> {/* width al 100% per riempire la colonna */}
                            <Card.Img variant="top" src={j82024} />
                            <Card.Body>
                                <Card.Title>Previsioni partita ITALIA SVIZZERA</Card.Title>
                                <Card.Text>
                                    (28 giugno 2024) Secondo il team di NGL Cila dovrebbero essere queste le previsioni per la partita di domani 29 giugno 2024. Speriamo di vincere.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="d-flex align-items-stretch"> {/* d-flex e align-items-stretch per l'allineamento */}
                        <Card style={{ width: '100%', height: 'auto' }}> {/* width al 100% per riempire la colonna */}
                            <Card.Img variant="top" src={j72024} />
                            <Card.Body>
                                <Card.Title>Risultati partita ITALIA CROAZIA</Card.Title>
                                <Card.Text>
                                    (24 giugno 2024) Risultato finale partita Italia Croazia (Crediti 433). Grazie a Mattia Zaccagniii ci siamo qualificati agli ottavi!
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Tops />
        </div>
    );
}

export default Football;

