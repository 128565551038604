import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navint() {
    return (
        <div>
            <Navbar  style={{ fontFamily: 'Inter, sans-serif', backgroundColor: '#007bff' }}> {/* Blu scuro */}
                <Container>
                    <Nav className="me-auto">
                        <Nav.Link href="/people" style={{ fontSize: '16px', fontWeight: 'normal', fontFamily:"Inter, sans-serif", color: 'white' }} aria-label="People">People</Nav.Link>
                        <Nav.Link href="/calcio" style={{ fontSize: '16px', fontWeight: 'bold', fontFamily:"Inter, sans-serif", color: 'white' }} aria-label="Calcio">Calcio</Nav.Link>
                        <Nav.Link href="/wiki" style={{ fontSize: '16px', fontWeight: 'bold', fontFamily:"Inter, sans-serif", color: 'white' }} aria-label="NGLPop">NGLPop!</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            <style jsx>{`
                @media (min-width: 768px) {
                    .nav-link {
                        font-size: 20px;
                    }
                }
            `}</style>
        </div>
    );
}

export default Navint;



