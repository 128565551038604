import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, ArcElement, BarElement, Tooltip, Legend } from 'chart.js';
import { Container, Row, Col } from 'react-bootstrap';

// Registrazione delle scale e degli elementi
Chart.register(CategoryScale, LinearScale, ArcElement, BarElement, Tooltip, Legend);

const StatisticsPanel = ({ data }) => {
    const barData = {
        labels: data.map(item => item.month),
        datasets: [
            {
                label: 'Visite Mensili',
                data: data.map(item => item.visits),
                backgroundColor: 'rgba(75,192,192,0.6)',
            },
        ],
    };

    const pieData = {
        labels: ['Desktop', 'Mobile', 'Tablet'],
        datasets: [
            {
                data: ['Desktop', 'Mobile', 'Tablet'].map(device => 
                    data.filter(item => item.device === device).reduce((acc, item) => acc + item.count, 0)
                ),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    return (
        <Container className="mt-5">
            <Row>
                <Col md={6}>
                    <h3>Visite Mensili</h3>
                    <Bar data={barData} />
                </Col>
                <Col md={6}>
                    <h3>Dispositivi Utilizzati</h3>
                    <Pie data={pieData} />
                </Col>
            </Row>
        </Container>
    );
};

export default StatisticsPanel;



