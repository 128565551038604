import React from "react";
import Navbarngl from './navbarngl';
import Tops from './top';
import ciclovie from './contents/retecicloviariangl.png';
import sngl1 from './contents/sngl1.png';
import ra14 from './contents/raccordo14.png';
import { Container, Image, ListGroup } from 'react-bootstrap';
import SignalTable from "./tabellasegnaletica";
import UserAccessData from "./geolocalizzazione";

function ReteCiclovaria() {
    return (
        <div style={{ backgroundColor: 'white', fontFamily: 'Inter, sans-serif' }}>
            <Navbarngl />
            <UserAccessData />
            <h1 style={{ textAlign: 'center' }}>Le ciclovie di NGL Cila</h1>
            <br />
            <p style={{ textAlign: 'center' }}>Il nuovo progetto di gestione delle piste ciclabili e delle strade principali rappresenta una nuova divisione di NGL Cila. Questo progetto mira a valorizzare il nostro territorio attraverso la manutenzione e l’abbellimento delle reti ciclabili esistenti, promuovendo l’uso della bicicletta tra le nuove generazioni.</p>
            <Container className="text-center">
                <Image src={ciclovie} fluid alt="Ciclovie" />
                <p>Illustrazione delle ciclovie gestite da NGL Cila</p>
            </Container>
            <Container>
                <h2>Strade principali e raccordi:</h2>
                <ListGroup>
                    <ListGroup.Item variant="primary">Ciclovia 1 (SNGL1) Cilavegna-Gravellona-Barbavara-Vigevano Piccolini. Inizio: Piazza Liberazione (Cilavegna) Fine: Rotonda Corso Aldo Moro (Vigevano)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 2 (SNGL2) Cilavegna-Case Nuove-Casoni della baita-Vigevano. Inizio: Via dei Mille (Cilavegna) Fine: Piazza Ducale (Vigevano)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 3 (SNGL3) Cilavegna-Tornaco. Inizio: Via Giuseppe verdi (Cilavegna) Fine: SP7 (Tornaco)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 4 (SNGL4) Cilavegna-Borgolavezzaro-Vespolate. Inizio: Piazza Liberazione (Cilavegna) Fine: Inizio di (Vespolate)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 5 (SNGL5) Cilavegna-Albonese. Inizio: Via Ramponata (Cilavegna) Fine: Via Negrone Manara (Albonese)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 6 (SNGL6) Case Nuove-Gravellona-Vignarello-Tornaco-Vespolate. Inizio: SNGL2 (Case Nuove) Fine: Centro Storico (Vespolate)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 7 (SNGL7) Cilavegna-Parona. Inizio: Via Sant'Anna (Cilavegna) Fine: Scocchiellina (Parona)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 8 (SNGL8) Cassolnovo-Molino del Conte.</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 9 (SNGL9) Cilavegna-Vignarello. Inizio: Via Per Vignarello (Cilavegna) Fine: Inizio Raccordo per Vignarello (RA4) (Vignarello)</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 10 (SNGL10) Cilavegna-Parona-Mortara. Inizio: Piazza Liberazione (Cilavegna) Fine: Mortara</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 11 (SNGL11) Case Nuove-Fogliano-Gambolò. Inizio: SNGL2 (Case Nuove) Fine: Gambolò</ListGroup.Item>
                    <ListGroup.Item variant="primary">Ciclovia 12 (SNGL10) Vigevano-Gambolò. Inizio: Parco parri (SNGL2) (Vigevano) Fine: Gambolò</ListGroup.Item>
                    <ListGroup.Item variant="success">Raccordo 1 (RA1) Inizio: SNGL1 Fine: Cassolnovo</ListGroup.Item>
                    <ListGroup.Item variant="success">Raccordo 13 (RA13) Inizio: SNGL3 Fine: Lago dell'olmo</ListGroup.Item>
                    <ListGroup.Item variant="success">Raccordo 2 (RA2) Inizio: SNGL4 Fine: Strada provinciale 4</ListGroup.Item>
                    <ListGroup.Item variant="success">Raccordo 3 (RA3) Inizio: Fine SNGL9 Fine: Vignarello</ListGroup.Item>
                    <ListGroup.Item variant="success">Raccordo 4 (RA4) Inizio: Vignarello SNGL6 Fine: Villanova</ListGroup.Item>
                    <ListGroup.Item variant="success">Raccordo 14 (RA14) Inizio: SNGL2 Circonvallazione Vigevano Fine: ITIS G. Caramuel (Vigevano)</ListGroup.Item>
                    <ListGroup.Item variant="secondary">Diramazione SNGL1/SNGL2 (SNGL1 dir.)</ListGroup.Item>
                </ListGroup>
                <h2>Incroci tra le varie ciclovie:</h2>
                <br />
                SNGL1: SNGL 4: Cilavegna/SNGL9: Cilavegna/SNGL 6: Gravellona/RA1: Barbavara.
                <br />
                SNGL2: SNGL4: (Cilavegna)/ SNGL7 (Cilavegna)/ SNGL1 mediante diramazione/SNGL6: (Case Nuove)/RA14 (Vigevano)/RA3 (Vigevano)
                <br />
                SNGL3: SNGL4 (Cilavegna)/RA3 (Lago dell'Olmo/Tornaco)/SNGL6 (Tornaco)
                <br />
                SNGL4: SNGL3 (Cilavegna)/SNGL6 (Vespolate)
                <br />
                SNGL5: RA3
                <br />
                SNGL6: SNGL2 (Case Nuove)/SNGL1 (Gravellona)/RA4 (Vignarello)/SNGL3 (Tornaco)/SNGL4: Vespolate
                <br />
                SNGL7: SNGL2 (Cilavegna)
                SNGL8: RA1 (Cassolnovo)
                SNGL9: SNGL1 (Cilavegna)/ RA4 (Vignarello)
                <h2>Segnaletica delle ciclovie</h2>
                <Image src={sngl1} fluid alt="Ciclovie" style={{ width: '50%', height: 'auto' }} />
                <p>Questo segnale indica una ciclovia (in particolar modo la ciclovia che iniziando per cilavegna arriva fino a Vigevano passando per gravellona)</p>
                <Image src={ra14} fluid alt="Ciclovie" style={{ width: '50%', height: 'auto' }} />
                <p>Questo segnale indica un raccordo ciclovie (in particolar modo quello che lega SNGL2 all'ITIS Caramuel di Vigevano. Ma questo segnale in Italia indica anche il raccordo autostradale 14 che parte da Opticina a Fernetti (TS)</p>
                <h3>Oltre alla segnaletica di indicazione strade, lungo le nostre ciclovie troverete anche i segnali stradali comuni, che regolamentano la circolazione.</h3>
                <SignalTable />
                <p>Queste indicazioni possono tornarvi utili anche quando viaggiate in bicicletta, anche senza percorrere per forza le nostre ciclovie.</p>

                <h1>L'obiettivo di questo progetto</h1>
                <p>Lo scopo è quello di collegare i territori del Pavese, con il basso Novarese e il Vercellese, colegando città come Pavia a Novara con sole piste ciclabili o tratte miste. Questo mira a valorizzare il valore del territorio in cui viviamo e pubblicizzando il muoversi in bicicletta da un comune all'altro</p>
            </Container>
            
           
            <Tops />
            <style jsx>{`
                @media (max-width: 768px) {
                    h1 {
                        font-size: 1.5rem;
                    }
                    h2 {
                        font-size: 1.2rem;
                    }
                    p {
                        font-size: 0.9rem;
                    }
                    .list-group-item {
                        font-size: 0.9rem;
                    }
                    .image {
                        width: 100%;
                        height: auto;
                    }
                }
            `}</style>
        </div>
    );
}

export default ReteCiclovaria;

